import { API_URL, GET_SINGLE_REPORT_URL } from "../config";
import fileDownload from "js-file-download";
import api from "./api";

const RELEVANT = {
  NO: "no"
}

const VARIANT_DETECTED = {
  YES: "Yes",
  NO: "No",
  CONFIRMED_YES: "confirmed-yes",
  NOT_CONFIRMED: "not-confirmed"
}

// Helper function to check gene relevance
const isGeneRelevant = (item, detailedFinding) => {
  return item?.associatedGene.includes(detailedFinding?.biomarkers?.gene_symbol);
};

// Helper function to check subcategory relevance
const isSubcategoryRelevant = (item, detailedFinding) => {
  return detailedFinding?.subcategory.includes(item?.title);
};

// Helper function to handle CNV status
const getCnvStatus = (cnvStatus, currentStatus) => {
  if (cnvStatus === VARIANT_DETECTED.CONFIRMED_YES) {
    return VARIANT_DETECTED.CONFIRMED_YES;
  } else if (cnvStatus === VARIANT_DETECTED.NOT_CONFIRMED) {
    return VARIANT_DETECTED.NOT_CONFIRMED;
  } else {
    return currentStatus !== VARIANT_DETECTED.NOT_CONFIRMED
      ? VARIANT_DETECTED.YES
      : currentStatus;
  }
};

const getVariantDetectedText = (item, patientGender, detailed_findings) => {
  if (item.gender && item.gender !== patientGender) {
    return RELEVANT.NO;
  }

  let variantDetectedText = VARIANT_DETECTED.NO;
  if (!detailed_findings) {
    return variantDetectedText;
  }

  for (let detailed_finding of detailed_findings) {
    if (isGeneRelevant(item, detailed_finding) && isSubcategoryRelevant(item, detailed_finding)) {
      if (detailed_finding?.biomarkers?.relevant === RELEVANT.NO
        && [RELEVANT.NO, VARIANT_DETECTED.NO].includes(variantDetectedText)) {
        variantDetectedText = RELEVANT.NO;
        continue;
      }

      variantDetectedText = getCnvStatus(detailed_finding?.biomarkers?.cnv_status, variantDetectedText);
      if (variantDetectedText === VARIANT_DETECTED.CONFIRMED_YES) {
        return variantDetectedText;
      }
    }
  }

  return variantDetectedText;
};


const isVariantDetected = (cnv_status) => {
  return !cnv_status || cnv_status === VARIANT_DETECTED.CONFIRMED_YES;
}

const isVariantNotConfirmed = (curation_data, draftOrder) => {

  if (!draftOrder) {
    return false;
  }

  const special_notes = draftOrder?.Report_JSON?.report?.special_notes;

  if (special_notes) {
    for (const geneData of curation_data) {
      const gene = geneData?.associatedGene[0];
      for (const note of special_notes) {
        if (note.gene_symbol === gene && note.cnv_status === VARIANT_DETECTED.NOT_CONFIRMED) {
          return true;
        }
      }
    }
  }  
}

const reportTypes = {
  cancer_risk: 'Cancer_Risk_Report',
  cardio_risk: 'Cardio_Report',
  metabolic_risk: 'IEM_Report',
  carrier_risk: 'Carrier_Risk_Report',
  pgx: 'PGx_Report',
  combined: 'Combined_Report'
}

const downloadReport = async (orderId, type) => {
  api({
    url: `${GET_SINGLE_REPORT_URL}`,
    method: "POST",
    params: { orderId: orderId },
  })
    .then(async (res) => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("accessToken")}`);
      const singleReport = await fetch(
        `${API_URL}/zoho/getSinglePdfReport`,
        {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            url: res?.data[0][type],
          }),
        }
      );

      // const singleReport = await axios.post(
      //   `${API_URL}/api/zoho/getSinglePdfReport`,
      //   {
      //       url: res?.data[0][type],
      //     },
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //     }
      //   }
      //     );

      const fileName = `${orderId + '_' + type}.pdf`;
      if (singleReport.ok) {
        let json = await singleReport.blob();
        fileDownload(json, fileName);
      } else {
        alert("HTTP-Error: " + singleReport.status);
      }

      return singleReport;
    })
    .catch((error) => {
      console.log(error);
    });
};

export default {
  RELEVANT: RELEVANT,
  VARIANT_DETECTED: VARIANT_DETECTED,
  getVariantDetectedText: getVariantDetectedText,
  isVariantDetected: isVariantDetected,
  downloadReport: downloadReport,
  isVariantNotConfirmed: isVariantNotConfirmed,
  reportTypes: reportTypes
}