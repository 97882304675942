import { initializeApp } from 'firebase/app';
import { getAuth, deleteUser } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getFirestore, doc, deleteDoc } from 'firebase/firestore';

const firebaseConfig = {
  // apiKey: "AIzaSyD3gj2V5KTHRIe7TSpwePjjbv8IpVT_MKM",
  // authDomain: "genomic-wellness.firebaseapp.com",
  // projectId: "genomic-wellness",
  // storageBucket: "genomic-wellness.appspot.com",
  // messagingSenderId: "626117497893",
  // appId: "1:626117497893:web:88d654259cdd315a9fdbcc"
  apiKey: "AIzaSyD3gj2V5KTHRIe7TSpwePjjbv8IpVT_MKM",
  authDomain: "genomic-wellness.firebaseapp.com",
  projectId: "genomic-wellness",
  storageBucket: "genomic-wellness.appspot.com",
  messagingSenderId: "626117497893",
  appId: "1:626117497893:web:88d654259cdd315a9fdbcc",
  measurementId: "G-NVHPR5CGFH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app); 
const firestore = getFirestore(app);

export { auth, deleteUser, firestore, doc, deleteDoc };
