import React, { useState, useEffect } from "react";
// import "./Carpo.css";
import Table from "react-bootstrap/Table";
import Sidepanel from "../../components/side-panel/side-panel";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useNavigate, Link, useSearchParams, useLocation } from "react-router-dom";
import PopService from "../../services/PopService";
import { useRef } from "react";
// import { carrierTypes } from "./report_callout";
import ReportUtils from "../../utils/ReportUtils";
import VariantStatus from "../variant-status/variant-status";
import DownloadIcon from "@mui/icons-material/Download";
import api from "../../utils/api";
import curation from "../../utils/curation";
import { GET_SINGLE_REPORT_URL, ENV } from "../../config";
import NotesAndLimitationsModal from "../notes-and-limitations-modal/NotesAndLimitationsModal";

export default function CarrierReport(props) {
  const topRef = useRef();
  const location = useLocation();
  const [isPanelOpen, setPanelOpen] = useState(false);
  props.onLoad(true);
  const [orderId, setOrderId] = useState();
  const [draftOrder, setDraftOrder] = useState([]);
  const [crsFinding, setCrsFinding] = useState(null);
  const [patientGender, setPatientGender] = useState(null);
  const [summaryDetails, setSummaryDetails] = useState([]);
  const [inheritance_Values, setInheritanceValues] = useState(new Set());
  const [error, setError] = useState(null);

  const carrierTypes = curation.getCurationData('My Carrier Risk Screening Report');

  let preDisorderType = '';

  const getDisorderType = (disorderType) => {
    if (disorderType === preDisorderType) return '';
    preDisorderType = disorderType;
    return preDisorderType;
  }

  function openPanel(event) {
    setPanelOpen(true);
  }

  function onPanelClose() {
    setPanelOpen(false);
  }

  function openPopup(data) {
    PopService.open("Carrier Risk Report", {
      data,
    });
  }

  const navigate = useNavigate();
  const naviagteToContact = () => {
    navigate("/contact");
  };

  useEffect(() => {
    topRef.current.scrollIntoView({
      behavior: "smooth",
    });
    getUrlData();
  }, []);

  const [queryParameters] = useSearchParams();

  const getUrlData = async () => {
    let orderId = queryParameters.get("orderId");
    setOrderId(orderId);
    const reports = await api({
      url: GET_SINGLE_REPORT_URL,
      method: "POST",
      params: { orderId },
    });

    setDraftOrder(reports?.data[0]);
    setPatientGender(reports?.data[0]?.Report_JSON?.report?.demographics?.gender);
    setCrsFinding(reports?.data[0]?.Report_JSON?.report?.
      findings?.find((finding) => finding?.category === "My Carrier Risk Screening Report"));



  };

  const inheritanceValues = new Set()

  useEffect(() => {
    // console.log("useEffect 2");
    if (ENV === 'development') {
      fetchData();
    }
  }, [inheritance_Values]);

  const fetchData = async () => {
    // console.log("fetchdata");
    try {
      const promises = Array.from(inheritance_Values).map(async (inheritance) => {
        // console.log(`Fetching data for inheritance: ${inheritance}`);
        const response = await api({
          url: '/summary/SummaryData',
          method: 'GET',
          params: { type: inheritance === 'NEGATIVE' ? 'All panels' : 'CRS', bucket: inheritance === 'X-Linked' ? (inheritance + ' (' + patientGender + ' sample)') : inheritance },
        });

        // console.log(`Response for ${inheritance}:`, response.data.data);

        return response.data.data;
      });

      const data = await Promise.all(promises);

      setSummaryDetails(data);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    // console.log("useEffect 3");
    (crsFinding?.summary_findings || crsFinding?.detailed_findings)?.map((finding) => {
      const isVariantDetected = ReportUtils.isVariantDetected(finding?.biomarkers?.cnv_status);
      if (isVariantDetected) {
        const inheritance = getInheritanceByGene(carrierTypes, finding?.biomarkers?.gene_symbol) || finding?.biomarkers?.inheritance;
        inheritanceValues.add(inheritance);
      }
    })

    if (inheritanceValues.size === 0) {
      inheritanceValues.add("NEGATIVE");
    }
    setInheritanceValues(inheritanceValues);
  }, [crsFinding])

  function getInheritanceByGene(data, gene) {

    // Find the object with the matching gene value
    const matchingItem = data.find(item => item.associatedGene.includes(gene));

    // If a matching item is found, return its "inheritance" value; otherwise, return null
    return matchingItem ? matchingItem.inheritance : null;
  }

  return (

    <div className="pageWrapper2">
      <div className="innerWrapper cancer-page container-fluid">
        <div ref={topRef}></div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadForMobile">
            <li className="breadcrumb-item">
              <a href={"#/dashboard" + location.search}>Dashboard</a>
            </li>
            <li className="breadcrumb-item marginTop">
              <a href={"#/crrierrisk" + location.search} >Carrier Risk</a>
            </li>
            <li
              className="breadcrumb-item active bg1 marginTop"
              aria-current="page"
            >
              Carrier Risk screening results
            </li>
          </ol>
        </nav>
        {isPanelOpen && (
          <Sidepanel open={isPanelOpen} onCloseCallback={onPanelClose} />
        )}

        <div className="card mt-1 p-3 p-md-5 pt-md-2 bxShdow bdrNone cardTransparent">
          <Row>
            <Col>
              <Row>
                <Col>
                  <h1 className="ff1 fs30 fw600 text-muted pt-3">
                    Carrier Risk Screening Results
                  </h1>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h4 className="fs20 pb-4">
                    {
                      crsFinding?.num_observations_of_significance > 0 ? (
                        <>
                          Your results have
                          {console.log(
                            crsFinding?.num_observations_of_significance
                          )}
                          <span
                            className={
                              crsFinding?.num_observations_of_significance !== 0
                                ? "variantHighlightRed"
                                : "variantHighlightGreen"
                            }
                          >
                            {
                              crsFinding?.num_observations_of_significance
                            }
                          </span>
                          observation/s of significance.
                        </>
                      ) : (
                        <> No observation/s of significance were detected.</>
                      )
                    }
                    {
                      <NotesAndLimitationsModal curationData={carrierTypes} draftOrder={draftOrder} />
                    }

                  </h4>
                </Col>
              </Row>
            </Col>
          </Row>

          {
            crsFinding?.num_observations_of_significance > 0 ? (<>
              <Row className="mt-5">
                <Col className="d-flex">
                  <h2 className="ff1 fs24 fw700">
                    Summary of Observations of Significance
                  </h2>
                </Col>
              </Row>

              <Row>
                <Col style={{ padding: "0px 16px" }}>
                  <div className="table-responsive border border-info">
                    <Table className="table table-striped table-responsive report-table mb-0 summary-report-table">
                      <thead>
                        <tr className="fw600 bg-info bg-gradient bg-opacity-50 text-nowrap">
                          <td style={{ width: "25%" }} title='Individual disorders bundled together due to similar clinical features or biological pathway of origin'>Broad Disorder Type <sup className="small-text">&#9432;</sup></td>
                          <td style={{ width: "20%" }} title='Specific clinical disease identified by the variant seen in the gene associated with the cause of disease'>Specific Disorder <sup className="small-text">&#9432;</sup></td>
                          <td style={{ width: "10%" }} title='Type of chromosome on which a gene is located (autosomal or X linked) and whether the variant is likely to manifest as disease (dominant or recessive). Eg; An “Autosomal Dominant” or “AD” allocation refers to the gene being autosomal in origin and the nature of the variant being “dominant” or “likely disease causing”'>Inheritance <sup className="small-text">&#9432;</sup></td>
                          <td style={{ width: "10%" }} title='Number of copies of a gene variant. Heterozygous refers to a single copy of the gene variant and Homozygous refers to both copies of a gene carrying a variant'>Zygosity <sup className="small-text">&#9432;</sup></td>
                          <td style={{ width: "10%" }} title='Name of the Gene carrying the variation'>Associated Gene(s) <sup className="small-text">&#9432;</sup></td>
                          <td style={{ width: "10%" }} title='Whether a Variant associated with “disease” has been called out or not'>Variant Detected <sup className="small-text">&#9432;</sup></td>
                          <td style={{ width: "15%" }} title='Technical nomenclature for the “Address” on the genome where the variant is located'>Gene Variant <sup className="small-text">&#9432;</sup></td>
                        </tr>
                      </thead>

                      <tbody>
                        {

                          //TODO: remove detailed_findings after regenerating all reports in backened
                          (crsFinding?.summary_findings || crsFinding?.detailed_findings)?.map((finding) => {
                            const isVariantDetected = ReportUtils.isVariantDetected(finding?.biomarkers?.cnv_status);
                            if (isVariantDetected) {
                              const inheritance = getInheritanceByGene(carrierTypes, finding?.biomarkers?.gene_symbol) || finding?.biomarkers?.inheritance;
                              return (
                                <tr>
                                  <td>
                                    {/* {console.log(finding?.subcategory[0])} */}
                                    <p>{finding?.subcategory[0]}</p>
                                  </td>
                                  <td>
                                    <p>{finding?.subcategory[1]}</p>
                                  </td>
                                  <td>
                                    {/* <p>{finding?.biomarkers?.inheritance}</p> */}
                                    {/* {console.log(inheritance)} */}
                                    <p>{inheritance}</p>
                                  </td>
                                  <td>
                                    <p>{finding?.biomarkers?.zygosity}</p>
                                  </td>
                                  <td style={{
                                    fontStyle: 'italic',
                                    textAlign: 'start',
                                    wordBreak: 'break-word',
                                  }}>
                                    <p>{finding?.biomarkers?.gene_symbol}</p>
                                  </td>
                                  <td>
                                    <VariantStatus text={finding?.biomarkers?.relevant || finding?.biomarkers?.cnv_status} draftOrder={draftOrder} />
                                  </td>
                                  <td>
                                    {finding?.biomarkers?.variant_description?.genomic_hgvs}
                                    <p style={{ margin: 0 }}>{finding?.biomarkers?.variant_description?.cdna_hgvs}</p>
                                    <p>{finding?.biomarkers?.variant_description?.protein_hgvs}</p>
                                  </td>
                                </tr>
                              )
                            }
                          })
                        }
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </>) : ""
          }

          {
            summaryDetails.length > 0 ? (
              <>
                {/* Render your second section */}
                <Row className="mt-3">
                  <Col className="d-flex">
                    {summaryDetails.some(data => data?.RISK_IMPLICATION) && (
                      <h4 className="ff1 fs30 fw600 text-muted pt-3">Risk Implications</h4>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col style={{ padding: "0px 16px" }}>
                    <div className="section-container">
                      {/* Risk Implication Section */}
                      <div className="section">
                        {summaryDetails.map((data, index) => (
                          data?.RISK_IMPLICATION && (
                            <div key={index} className="data-item">
                              <p>{data.RISK_IMPLICATION}</p>
                            </div>
                          )
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col className="d-flex">
                    {summaryDetails.some(data => data?.RECOMMENDATION) && (
                      <h4 className="ff1 fs30 fw600 text-muted pt-3">Recommendations</h4>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col style={{ padding: "0px 16px" }}>
                    <div className="section-container">
                      {/* Recommendation Section */}
                      <div className="section">
                        {summaryDetails.map((data, index) => (
                          data?.RECOMMENDATION && (
                            <div key={index} className="data-item">
                              <p>{data.RECOMMENDATION}</p>
                            </div>
                          )
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            ) : ""
          }

          <Row className="mt-5">
            <Col>
              <div style={{ display: "flex" }}>
                <h2 className="ff1 fs30 fw700">Detailed Findings</h2>
              </div>
            </Col>
          </Row>


          <div className="table-health-1 reportTable border border-dark-200">
            <Table
              striped
              responsive="lg"
              className="table report-table mb-0 detailed-report-table"
            >
              <thead className="row-head">
                <tr className="fw600 bg-primary bg-gradient bg-opacity-50 text-white text-nowrap">
                  <td title='Individual disorders bundled together due to similar clinical features or biological pathway of origin'>
                    Broad Disorder Type <sup className="small-text">&#9432;</sup>
                  </td>
                  <td title='Specific clinical disease identified by the variant seen in the gene associated with the cause of disease'>Associated Conditions <sup className="small-text">&#9432;</sup></td>
                  <td>Inheritance</td>
                  <td title='Name of the Gene carrying the variation'> Associated Gene(s) <sup className="small-text">&#9432;</sup></td>
                  <td title='Whether a Variant associated with “disease” has been called out or not'>Variant Detected <sup className="small-text">&#9432;</sup></td>
                </tr>
              </thead>
              <tbody>
                {carrierTypes.map((item) => (
                  <tr className="row-color">
                    <td>{getDisorderType(item?.diorderType)} </td>
                    <td className="">
                      <div className="dropdown">
                        <button className="btn btn-link text-start" onClick={item?.popupFunction}>
                          {item?.title}
                        </button>
                        <div className="dropdown-content popToolTip">
                          <p>
                            {item?.icard}
                          </p>
                          <Link
                            onClick={item.popupFunction}
                            style={{
                              cursor: "pointer",
                              fontSize: "12px",
                              color: "#0000EE",
                            }}
                          >
                            Read more
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item?.inheritance}
                    </td>
                    <td style={{
                      fontStyle: 'italic',
                      textAlign: 'start',
                      wordBreak: 'break-word',
                    }}>
                      {item?.associatedGene.join(", ")}
                    </td>
                    <td>
                      <VariantStatus text={ReportUtils.getVariantDetectedText(item, patientGender, crsFinding?.detailed_findings)} gene={item?.associatedGene[0]} draftOrder={draftOrder} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <Container fluid>
            <Row className="mt-5">
              <Col className="p-c2">
                <div className="d-flex flex-column">
                  <div className="d-md-flex flex-column report-buttons  text-center text-md-left">
                    <button
                      className="schApp text-center position-relative"
                      onClick={naviagteToContact}
                    >
                      <span className="schIcon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="60"
                          height="60"
                          fill="#000"
                          className="bi bi-telephone"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                      </span>
                      {""}Schedule appointment with Genetic Counsellor for detailed
                      explanation
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          {/* <Row>
            <Col>
              <div style={{ display: "flex" }}>
                <h2 className="ff1 fs30 fw600">Recommendations</h2>
              </div>
            </Col>
          </Row> */}

          <Row>
            <Col lg={12} md={6} xs={12}>
              <div className="mb-3">
                {/* <p className="fs18">
                  Based on the variants seen in your test it is imperative to
                  ascertain the variant status of your partner in order to
                  rule out a similar test result when planning to have children.{" "}
                </p>

                <p className="fs18">
                  To understand the implications of the above variants, if any,
                  please schedule an appointment with a Strand genetic
                  counselor. Subsequently, you can also download the detailed
                  report below and take it to a medical specialist.{" "}
                </p> */}

                <h4 className="fs18 fw800 fst-italic">Disclaimer</h4>
                <p className="pt-0 fs18 fst-italic">
                  Note that the above information is not intended for any action
                  on your part for diagnosis, cure, mitigation, treatment or
                  prevention of disease. Any action based on this information
                  should be taken only on the recommendation of a medical
                  specialist.
                </p>
              </div>
            </Col>
          </Row>
          <button className="btn7 mt-5" onClick={() => ReportUtils.downloadReport(orderId, ReportUtils.reportTypes.carrier_risk)}>
            <span>{<DownloadIcon />}</span>
            Download Report (PDF)
          </button>
        </div>
      </div>
    </div>
  );
}
