import axios from 'axios';
import { API_URL } from '../config'; 
import { X_API_KEY } from '../config';
import { red } from '@mui/material/colors';
import { redirect } from 'react-router-dom';


const logout = () => {
  localStorage.clear();
  window.location.replace("#/login");
  window.location.reload(false);
};

const api = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    // Retrieve the access token from local storage
    const accessToken = localStorage.getItem('accessToken');

    // Add the access token to the request headers
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    config.headers['x-api-key'] = X_API_KEY;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Check if the error status is 401 (unauthorized) and if the original request was not already retried
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Call an endpoint to refresh the access token
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        // If the refresh token is available, send a request to refresh the access token
        api.post('/user/refreshToken', {
          refreshToken,
        }).then((res) => {
          if (res.data && res.data.accessToken) {
            localStorage.setItem('accessToken', res.data.accessToken);
            return api(originalRequest);
          }
        }).catch((err) => {
          logout();
        });
      }
       else {
        logout();
      }
    }
    return Promise.reject(error);
  }
);

export default api;
