import React, { useEffect, useState } from "react";
// import "./Metarepo.css";
import Table from "react-bootstrap/Table";
import Sidepanel from "../../components/side-panel/side-panel";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useNavigate, Link, useSearchParams, useLocation } from "react-router-dom";
import PopService from "../../services/PopService";
import { useRef } from "react";
import ModalService from "../../services/ModalService";
import ReportUtils from "../../utils/ReportUtils";
import VariantStatus from "../variant-status/variant-status";
import DownloadIcon from "@mui/icons-material/Download";
import api from "../../utils/api";
import curation from "../../utils/curation";
import { GET_SINGLE_REPORT_URL, ENV } from "../../config";
import NotesAndLimitationsModal from "../notes-and-limitations-modal/NotesAndLimitationsModal";


export default function Metarepo(props) {
  const location = useLocation();
  const topRef = useRef();
  const [orderId, setOrderId] = useState("");
  const [draftOrder, setDraftOrder] = useState([]);
  const [isPanelOpen, setPanelOpen] = useState(false);
  props.onLoad(true);
  function openPanel(event) {
    setPanelOpen(true);
  }

  const [iemFinding, setIemFinding] = useState(null);
  const [patientGender, setPatientGender] = useState(null);
  const [summaryDetails, setSummaryDetails] = useState([]);
  const [inheritance_Values, setInheritanceValues] = useState(new Set());
  const [error, setError] = useState(null);

  const [queryParameters] = useSearchParams()
  useEffect(() => {
    getUrlData()
  }, [])
  const getUrlData = async () => {
    let orderId = queryParameters.get("orderId")
    setOrderId(orderId);
    const reports = await api({
      url: GET_SINGLE_REPORT_URL,
      method: "POST",
      params: { orderId },
    });

    setDraftOrder(reports?.data[0]);
    setPatientGender(reports?.data[0]?.Report_JSON?.report?.demographics?.gender);
    setIemFinding(reports?.data[0]?.Report_JSON?.report?.findings?.find(
      (finding) =>
        finding?.category === "In-born Errors of Metabolism Report"
    ));
  }

  const inheritanceValues = new Set()

  useEffect(() => {
    // console.log("useEffect 2");
    if (ENV === 'development') {
      fetchData();
    }

  }, [inheritance_Values]);

  const fetchData = async () => {
    // console.log("fetchdata");
    try {
      const promises = Array.from(inheritance_Values).map(async (inheritance) => {
        // console.log(`Fetching data for inheritance: ${inheritance}`);
        const response = await api({
          url: '/summary/SummaryData',
          method: 'GET',
          params: { type: inheritance === 'NEGATIVE' ? 'All panels' : 'IEM', bucket: inheritance },
        });

        // console.log(`Response for ${inheritance}:`, response.data.data);

        return response.data.data;
      });

      const data = await Promise.all(promises);

      setSummaryDetails(data);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    // console.log("useEffect 3");
    (iemFinding?.summary_findings || iemFinding?.detailed_findings)?.map((finding) => {
      const isVariantDetected = ReportUtils.isVariantDetected(finding?.biomarkers?.cnv_status);
      if (isVariantDetected) {
        inheritanceValues.add(finding?.biomarkers?.inheritance);
      }
    })
    // console.log("setInheritanceValues", inheritanceValues);
    // inheritanceValues.add("AR");
    // inheritanceValues.add("X-Linked (Male sample)");

    if (inheritanceValues.size === 0) {
      inheritanceValues.add("NEGATIVE");
    }
    setInheritanceValues(inheritanceValues);
  }, [iemFinding])


  let preDisorderType = '';

  const getDisorderType = (disorderType) => {
    if (disorderType === preDisorderType) return '';
    preDisorderType = disorderType;
    return preDisorderType;
  }

  // const metaTypes = [
  //   {
  //     value: "fructosemia",
  //     diorderType: "Carbohydrate metabolism disorder",
  //     title: "Fructosemia",
  //     associatedGene: ["ALDOB"],
  //     icard:
  //       "Fructosemia or hereditary fructose intolerance is caused by alteration in the ALDOB gene which encodes for the enzyme fructose-bisphosphate B aldolase, important for digesting fructose or fruit sugars.",
  //     popupFunction: function openModal500(event) {
  //       ModalService.open(
  //         "Fructosemia ",
  //         "Fructosemia or hereditary fructose intolerance is caused by alteration in the ALDOB gene which encodes for the enzyme fructose-bisphosphate B aldolase, important for digesting fructose or fruit sugars. Fructose intolerance is detected in majority of the cases in infancy.. However mild cases go undetected and untreated until later life. Individuals with fructose intolerance are unable to digest fruits or sugary food, and experience severe abdominal pain, vomiting, jaundice, a fall in blood sugar sometimes leading to unconsciousness, especially after eating sugary food. In cases where the condition is not diagnosed and diet is not appopriately modified, serious damage to the liver and kidney can happen."
  //       );
  //     },
  //   },

  //   // {
  //   //   value: "methylmalonicaciduriaandhomocystinuria",
  //   //   diorderType:"Lipid and lipoprotein disorder",
  //   //   title: "Methylmalonic aciduria and homocystinuria",
  //   //   associatedGene: [
  //   //     "MMUT"
  //   //   ],
  //   //   icard:
  //   //     "Methylmalonic aciduria caused by the deficiency of methylmalonyl CoA enzyme which impacts the breakdown and rerouting of amino acids and fats into energy producing pathways inside cells.",
  //   //   popupFunction:   function openModal510(event){
  //   //     ModalService.open("Methylmalonic aciduria and homocystinuria ", "Methylmalonic aciduria caused by the deficiency of methylmalonyl CoA enzyme which impacts the breakdown and rerouting of amino acids and fats into energy producing pathways inside cells. Depending on the type of mutation, the symptoms can be mild to severe. In adults, the features manifest suddenly and range from muscle weakness in lower limbs, slow speech, forgetfulness, trouble controlling bowel movement. Movement gets rapidly compromised and there is enhanced risk of clots and emobolisms in legs.")
  //   //   }
  //   // },

  //   {
  //     value: "Gauchers Disease",
  //     diorderType: "Lysosomal Storage Disease",
  //     title: "Gaucher disease",
  //     associatedGene: ["GBA1"],
  //     icard:
  //       "Gaucher disease type 1 is caused by a deficiency or alteration of the enzyme beta-glucocerebrosidase breaks down a fatty substance called glucocerebroside into a sugar (glucose) and a simpler fat molecule (ceramide).",
  //     popupFunction: function openModal530(event) {
  //       ModalService.open(
  //         "Gauchers Disease ",
  //         "Gaucher disease type 1 is caused by a deficiency or alteration of the enzyme beta-glucocerebrosidase breaks down a fatty substance called glucocerebroside into a sugar (glucose) and a simpler fat molecule (ceramide). This results in build-up of certain fatty substances in certain organs, particularly spleen and liver. Gaucher Disease type 1 is the most common form and may appear anytime from childhood to adulthood. Main symptoms include enlargement of the liver and spleen, low number of red blood cells, easy bruising of skin caused by a decrease in blood platelets, bone abnormalities such causing pain and fractures, and joint conditions such as arthritis.Treatment involves enzyme replacement therapy and a large fraction of affected individuals respond well to it."
  //       );
  //     },
  //   },

  //   {
  //     value: "x-linkedadrenoleukodystrophy",
  //     diorderType: "Disorders of sterol biosynthesis",
  //     title: "X-linked adrenoleukodystrophy",
  //     associatedGene: ["ABCD1"],
  //     icard:
  //       "Adrenoleukodystrophy (ALD) is caused by an altered or absent adrenoleukodystrophy protein, which is crucial for transporting fat molecules into specific cellular compartments for breakdown.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "X-linked adrenoleukodystrophy",
  //         "Adrenoleukodystrophy (ALD) is caused by an altered or absent adrenoleukodystrophy protein, which is crucial for transporting fat molecules into specific cellular compartments for breakdown. Adrenoleukodystrophy causes a range of conditions where there is build-up of fat molecules in the brain and adrenal glands of the body. Late onset conditions show up as deficiency in key hormones secreted by adrenal gland causing fatigue,weight loss, vomiting, gastrointestinal issues, general weakness and low blood pressure and low blood sugar. Some patients also display muscle stiffness and pain."
  //       );
  //     },
  //   },

  //   {
  //     value: "nul",
  //     diorderType: "Amino acid, Peptide metabolism & Urea cycle disorder",
  //     title: "Argininosuccinic Aciduria",
  //     associatedGene: ["ASL"],
  //     icard:
  //       "Argininosuccinate aciduria is caused by the loss of the enzyme arginosuccinate lyase which is involved in the processing of processing of proteins in the body.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Amino acid, Peptide metabolism & Urea cycle disorder",
  //         "Argininosuccinate aciduria is caused by the loss of the enzyme arginosuccinate lyase which is involved in the processing of processing of proteins in the body. This deficiency causes a build up of ammonia in the body. Excess ammonia build up results in brain, kidney and liver damage. Though mainly detected at birth, a milder form of this condition can have adult-onset, where lethargy, enlarged liver, mental impairments and seizures are seen. Treatment involves strict dietary control."
  //       );
  //     },
  //   },

  //   {
  //     value: "hyperoxaluria",
  //     diorderType: " ",
  //     title: "Hyperoxaluria",
  //     associatedGene: ["AGXT"],
  //     icard:
  //       "Primary hyperoxalouria type I is caused by the lack of an enyzme alanine--glyoxylate aminotransferase, which prevents the accumulation of oxalate in the liver and kidneys.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Hyperoxaluria",
  //         "Primary hyperoxalouria type I is caused by the lack of an enyzme alanine--glyoxylate aminotransferase, which prevents the accumulation of oxalate in the liver and kidneys. In the absence of the enzyme, calcium-oxalate deposition occurs which are the main components of urinary and kidney stones. Affected individuals have recurrent stones forming in the kidney and liver leading to progressive kidney impairment and eventual failure. Many individuals do not show any symptoms and are diagnosed very late."
  //       );
  //     },
  //   },

  //   {
  //     value: "Homocystinuria",
  //     diorderType: "",
  //     title: "Homocystinuria",
  //     associatedGene: ["CBS", "MTHFR", "MTR", "MTRR", "MMADHC"],
  //     icard:
  //       "Homocystinuria is caused by a defective version of the enzyme cystathionine beta-synthase leading to a build-up of cystathione in the blood and increased excretion in the urine.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Homocystinuria",
  //         "Homocystinuria is caused by a defective version of the enzyme cystathionine beta-synthase leading to a build-up of cystathione in the blood and increased excretion in the urine. Cystathionine is normally processed to make specific kinds of biomolecules needed for growth and maintenance. Individuals in many cases have not shown any symptoms until adulthood. Psychiatric problems, altered muscle tone and strength, vision related difficulties and altered function of blood vessels are adult-related features. Some forms of the defective enzyme can be treated with high doses of Vitamin B6 while other mutations (B6-non responsive) that do not show effect with Vit B6 are treated with Trimethylglycine."
  //       );
  //     },
  //   },

  //   {
  //     value: "Glutaric aciduria type 1",
  //     diorderType: "",
  //     title: "Glutaric aciduria type 1",
  //     associatedGene: ["GCDH"],
  //     icard:
  //       "Glutaric aciduria type 1 is caused by alterations in enzyme glutaryl-CoA dehydrogenase which is important for the breakdown and processing of amino acids lysine, hydroxylysine, and tryptophan.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Glutaric aciduria type 1",
  //         "Glutaric aciduria type 1 is caused by alterations in enzyme glutaryl-CoA dehydrogenase which is important for the breakdown and processing of amino acids lysine, hydroxylysine, and tryptophan, which are building blocks of protein. Only in a small number of affected individuals, the disorder becomes apparent in adolescence or adulthood. Here, the features manifest as a result of protein-heavy meals, and include an “acute encephalopathic crisis” that shows up as generalized neurological symptoms like Irritability, jitteriness, vomiting, diarrhea, low muscle tone, lack of energy/sleepy."
  //       );
  //     },
  //   },

  //   {
  //     value: "L-2-hydroxyglutaric aciduria",
  //     diorderType: "",
  //     title: "L-2-hydroxyglutaric aciduria",
  //     associatedGene: ["L2HGDH"],
  //     icard:
  //       "L-2 hydroxyglutaric aciduria is a genetic condition resulting from a deficiency of the L-2-hydroxyglutarate dehydrogenase that leads to a buildup of L-hydroxyglutaric acid in the body.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "L-2-hydroxyglutaric aciduria",
  //         "L-2 hydroxyglutaric aciduria is a genetic condition resulting from a deficiency of the L-2-hydroxyglutarate dehydrogenase that leads to a buildup of L-hydroxyglutaric acid in the body. Though the disease sets in during infancy or early childhood, it progresses quite slowly and in several cases a diagnosis is only made during early (17-25) or even late (50-70) adulthood. High levels of L-hydroxyglutaric acid in body fluids such as urine and cerebrospinal fluid is a key marker of this disease. Clinical symptoms show as loss of muscle control and co-ordination, worsening speech and balance, involuntary contractions or spasms, seizures and epilepsy. Structural abnomalities and degeneration in several structures in the brain are also seen, leading to psychological and cognitive impairment, even parkinsonism in older patients."
  //       );
  //     },
  //   },

  //   {
  //     value: "Methylmalonic aciduria–methylmalonyl–CoA mutase deficiency",
  //     diorderType: "",
  //     title: "Methylmalonic aciduria–methylmalonyl–CoA mutase deficiency",
  //     associatedGene: ["MMUT"],
  //     icard:
  //       "Methylmalonic aciduria caused by the deficiency of methylmalonyl CoA enzyme which impacts the breakdown and rerouting of amino acids and fats into energy producing pathways inside cells.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Methylmalonic aciduria–methylmalonyl–CoA mutase deficiency",
  //         "Methylmalonic aciduria caused by the deficiency of methylmalonyl CoA enzyme which impacts the breakdown and rerouting of amino acids and fats into energy producing pathways inside cells. Depending on the type of mutation, the symptoms can be mild to severe. In adults, the features manifest suddenly and range from muscle weakness in lower limbs, slow speech, forgetfulness, trouble controlling bowel movement. Movement gets rapidly compromised and there is enhanced risk of clots and emobolisms in legs."
  //       );
  //     },
  //   },

  //   {
  //     value: "Ornithine transcarbamylase deficiency",
  //     diorderType: "",
  //     title: "Ornithine transcarbamylase deficiency",
  //     associatedGene: ["OTC"],
  //     icard:
  //       "Ornithine transcarbamylase deficiency is caused by the reduction of a functional enzyme of the same name. The enzyme is critical for processing body wastes through a process called the urea cycle",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Ornithine transcarbamylase deficiency",
  //         "Ornithine transcarbamylase deficiency is caused by the reduction of a functional enzyme of the same name. The enzyme is critical for processing body wastes through a process called the urea cycle. Deficiency of the same causes a build-up of urea in the blood stream with fallout on multiple organs.The late-onset form of the disorder occurs in both males and females. People with late-onset ornithine transcarbamylase deficiency may experience episodes of altered mental status, such as delirium, erratic behavior, or a reduced level of consciousness. Headaches, vomiting, aversion to protein foods, and seizures can also occur in this form of the disorder."
  //       );
  //     },
  //   },

  //   {
  //     value: "Cystinuria",
  //     diorderType: "",
  //     title: "Cystinuria",
  //     associatedGene: ["SLC3A1", "SLC7A9"],
  //     icard:
  //       "Cystinuria is an inherited condition where defects in genes SLC3A1 and SLC7A9 cause abnormalities in proteins components of a large complex necessary for the reabsorption of certain amino acids like cystine",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Cystinuria",
  //         "Cystinuria is an inherited condition where defects in genes SLC3A1 and SLC7A9 cause abnormalities in proteins components of a large complex necessary for the reabsorption of certain amino acids like cystine, ornithine, arginine etc, in the kidneys. Abnormal function of this protein complex causes build up of these amino acids in the body. Among these, cystine does not dissolve well in urine, and hence gets deposited in the kidney. Over time, cystine deposits in combination with calcium and other such molecules form hard stones with jagged edges. Stones in the kidney result in sharp pain, bleeding and injury to the kidney, ureter, urethra, recurrent infections and kidney damage. Symptoms usually start appear from teens to adulthood, with recurrent episodes of stone formation."
  //       );
  //     },
  //   },

  //   {
  //     value: "Phenylketonuria",
  //     diorderType: "",
  //     title: "Phenylketonuria",
  //     associatedGene: ["PAH"],
  //     icard:
  //       "Phenyketonuria is caused by an alteration in the phenylalanine hydroxylase enzyme that helps regulate the levels of amino acid phenylalanine in the body.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Phenylketonuria",
  //         "Phenyketonuria is caused by an alteration in the phenylalanine hydroxylase enzyme that helps regulate the levels of amino acid phenylalanine in the body. Deficiency of normal function enzyme leads to a build up of phenylalanine when a diet rich in this amino acid or aspartame is consumed. Milder forms of PKU are detected at adulthood, and are usually associated with memory loss, behavioral problems, and difficulties with."
  //       );
  //     },
  //   },

  //   {
  //     value: "Galactosialidosis",
  //     diorderType: "",
  //     title: "Galactosialidosis",
  //     associatedGene: ["CTSA"],
  //     icard:
  //       "Galactosialidosis (GS) is caused by a deficiency of CTSA gene product which codes for the enzyme Cathepsin A which is instrumental in breaking down many different kinds of glycoproteins.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Galactosialidosis",
  //         "Galactosialidosis (GS) is caused by a deficiency of CTSA gene product which codes for the enzyme Cathepsin A which is instrumental in breaking down many different kinds of glycoproteins. The build up of glycoproteins in the absence of Cathepsin impacts different body systems, including the brain, eyes, muscles, and skeleton. The most common type is juvenile/adult galactosialidosis and displays characteristic features such as facial deformities, vertebral changes, cherry-red spots and corneal clouding. Some severe neurologic features may include seizures, issues with control of voluntary muscle movement and progressive mental impairment."
  //       );
  //     },
  //   },

  //   {
  //     value: "Retinitis Pigmentosa 59",
  //     diorderType: "",
  //     title: "Retinitis Pigmentosa 59",
  //     associatedGene: ["DHDDS"],
  //     icard:
  //       "Retinitis pigmentosa 59 is a type of progressive vision disorder caused by the deficiency or loss of enzyme Dehydrodolichyl diphosphate synthase, which is important in formation of a specific set of proteins in the body called glycoproteins.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Retinitis Pigmentosa 59",
  //         "Retinitis pigmentosa 59 is a type of progressive vision disorder caused by the deficiency or loss of enzyme Dehydrodolichyl diphosphate synthase, which is important in formation of a specific set of proteins in the body called glycoproteins. Glycoproteins are critical for immunity, endocrine balance, reprduction and general cell to cell identification and communication.The specific features of this condition include night and peripheral vision loss, tunel vision, selective degeneration of eye cells important for color vision (cone cells) eventually leading to blindness."
  //       );
  //     },
  //   },

  //   {
  //     value: "Pompe disease",
  //     diorderType: "",
  //     title: "Pompe disease",
  //     associatedGene: ["GAA"],
  //     icard:
  //       "Pompe disease or glycogen storage disease II (GSD2) is caused by deficiency of the gene product GAA, which encodes the enzyme Lysosomal alpha-glucosidase",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Pompe disease",
  //         "Pompe disease or glycogen storage disease II (GSD2) is caused by deficiency of the gene product GAA, which encodes the enzyme Lysosomal alpha-glucosidase, essential for the degradation of glycogen in lysosomes. Most individuals with late-onset Pompe disease experience progressive muscle weakness, especially in the legs and the trunk, including the muscles that control breathing. As the disorder progresses, breathing problems can lead to respiratory failure. High protein diet is said to be effective in adults."
  //       );
  //     },
  //   },

  //   {
  //     value: "Krabbe disease",
  //     diorderType: "",
  //     title: "Krabbe disease",
  //     associatedGene: ["GALC"],
  //     icard:
  //       "Krabbe’s Leukodystrophy is caused by a deficiency of the enzyme galoctocerebrocidase. This enzyme is needed for the metabolism of galactocerebroside, a component of the fatty sheath around the nerves.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Krabbe disease",
  //         "Krabbe’s Leukodystrophy is caused by a deficiency of the enzyme galoctocerebrocidase. This enzyme is needed for the metabolism of galactocerebroside, a component of the fatty sheath around the nerves. Absence of this enzyme causes the stripping away of the myelin sheath of nerve cells, which impacts nerve to nerve signal conduction and underlies the neurological features seen in affected people. This metabolic disorder is characterized by progressive neurological dysfunction such as intellectual disability, paralysis, blindness, deafness and paralysis of certain facial muscles. In the juvenile and adult forms of Krabbe’s Leukodystrophy, the initial symptom may be impaired control of voluntary movements and progressive rigidity of muscles in the legs. Affected individuals with these forms of the disorder may also experience progressive vision loss and disease affecting multiple nerves."
  //       );
  //     },
  //   },

  //   {
  //     value: "GBE1-related disorders (Adult polyglucosan body disease)",
  //     diorderType: "",
  //     title: "GBE1-related disorders (Adult polyglucosan body disease)",
  //     associatedGene: ["GBE1"],
  //     icard:
  //       "Adult polyglucosan body disease is a condition that is caused by the reduction or abberant function of the enzyme 1,4-alpha-glucan branching enzyme 1, critical for the production of glycogen in the body.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "GBE1-related disorders (Adult polyglucosan body disease)",
  //         "Adult polyglucosan body disease is a condition that is caused by the reduction or abberant function of the enzyme 1,4-alpha-glucan branching enzyme 1, critical for the production of glycogen in the body. Glycogen is an important energy storage molecule.The late onset form of this condition primarily affects the nervous system. Numbness and tingling in the legs is accompanied by progressive muscle weakness and stiffness. This causes poor balance, and an increased risk of falling. Nervous control of bladder, bowel and sexual response also gets impacted. A large group of affected individuals also report problems with blood pressure, heart rate, breathing rate, digestion, temperature regulation with failing memory."
  //       );
  //     },
  //   },

  //   {
  //     value: "GM1 gangliosidosis (Type III)",
  //     diorderType: "",
  //     title: "GM1 gangliosidosis (Type III)",
  //     associatedGene: ["GLB1"],
  //     icard:
  //       "GM1 gangliosidosis type III is caused by genetic changes that reduce or remove the enzyme beta-galatosidase, which is important for breaking down the complex fat GM1 ganglioside in special structures in cells called lysosomes.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "GM1 gangliosidosis (Type III)",
  //         "GM1 gangliosidosis type III is caused by genetic changes that reduce or remove the enzyme beta-galatosidase, which is important for breaking down the complex fat GM1 ganglioside in special structures in cells called lysosomes. This absence of protein therefore leads to a build up of GM1 ganglioside in cells that irreversibly damage nerve cells in the brain and spinal cord. Type III GM1 gangliosidosis impacts adults. Symptoms include muscle weakness,uncontrolled movements called dystonia. Individuals may also report eye disease impacting the cornea (whites of the eye) and appearance of non-cancerous skin lesions."
  //       );
  //     },
  //   },

  //   {
  //     value: "Maturity Onset Diabetes type 13",
  //     diorderType: "",
  //     title: "Maturity Onset Diabetes type 13",
  //     associatedGene: ["KCNJ11"],
  //     icard:
  //       "Maturity-onset diabetes of the young (MODY) is a group of several conditions characterized by abnormally high blood sugar levels. These forms of diabetes typically begin before age 30",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Maturity Onset Diabetes type 13",
  //         "Maturity-onset diabetes of the young (MODY) is a group of several conditions characterized by abnormally high blood sugar levels. These forms of diabetes typically begin before age 30, although they can occur later in life. In MODY, elevated blood sugar arises from reduced production of insulin, which is a hormone produced in the pancreas that helps regulate blood sugar levels. Specifically, insulin controls how much glucose (a type of sugar) is passed from the blood into cells, where it is used as an energy source.Type 13 MODY is caused by defective functioning of the KCNJ11 gene product that synthesizes a ionic channel which allows for transport of potassium in the insulin secreting cells."
  //       );
  //     },
  //   },

  //   {
  //     value: "Sialidosis type 1",
  //     diorderType: "",
  //     title: "Sialidosis type 1",
  //     associatedGene: ["KCNJ11"],
  //     icard:
  //       "Sialidosis, also known as mucolipidosis type I, is caused by the deficiency of the enzyme neuraminidase",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Sialidosis type 1",
  //         "Sialidosis, also known as mucolipidosis type I, is caused by the deficiency of the enzyme neuraminidase, which e is required to remove specialized sugar chains from glycoproteins to release a chemical called sialic acid. This is carried out in specialized structures within cells called lysosomes. Deficiency of neuraminidase results in the abnormal accumulation of these glycoproteins in the body. Type I sialidosis begins in the second decade of life with symptoms of sudden involuntary muscle contractions, characteristic red spots in the eyes, and sometimes neurological abnormalities."
  //       );
  //     },
  //   },

  //   {
  //     value: "Phosphomannomutase 2 deficiency (PMM2-CDG-1A)",
  //     diorderType: "",
  //     title: "Phosphomannomutase 2 deficiency (PMM2-CDG-1A)",
  //     associatedGene: ["PMM2"],
  //     icard:
  //       "Phosphomannomutase 2 deficiency (PMM2-CDG-1A) is ais cuased by inadequate functioning of the phosphomannpmutase 2 (PMM2)enzyme which is repsonsible for attaching large sugar chain molecules",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Phosphomannomutase 2 deficiency (PMM2-CDG-1A)",
  //         "Phosphomannomutase 2 deficiency (PMM2-CDG-1A) is ais cuased by inadequate functioning of the phosphomannpmutase 2 (PMM2)enzyme which is repsonsible for attaching large sugar chain molecules to specific proteins for healthy cell structure and chemical reactions. Abnormal function of PMM2 As a result many organ systems are affected, particularly the brain. This disease sets in mainly in infancy, but in certain cases where there are no neurological abnormalities diagnosis may happen in adoloscence or adulthood. Adult individuals with this condition, especially females, often do not go through puberty because of hormonal malfunction and underdeveloped ovaries. The cerebellum, an area of the brain important for movement and balance shows degeneration, resulting in loss of muscle tone and movement co-ordination. Retinal cell breakdown, neurological and cognitive delays, nerve damage in the hands and feet, and abnormalities in blood-coagulation are also seen."
  //       );
  //     },
  //   },

  //   {
  //     value: "Carbohydrate-deﬁcient glycoprotein syndrome type Ia",
  //     diorderType: "",
  //     title: "Carbohydrate-deﬁcient glycoprotein syndrome type Ia",
  //     associatedGene: ["PMM2"],
  //     icard:
  //       "Carbohydrate-deﬁcient glycoprotein syndrome type Ia is caused by a deficiency in the enzyme phosphomannomutase that is required for the production of a special class of proteins called glycoproteins.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Carbohydrate-deﬁcient glycoprotein syndrome type Ia",
  //         "Carbohydrate-deﬁcient glycoprotein syndrome type Ia is caused by a deficiency in the enzyme phosphomannomutase that is required for the production of a special class of proteins called glycoproteins. In adult onset conditions stage no impact is seen on intellectual ability, however peripheral nerve pain is reported. In addition, progressive and agressive shortsightedness and vision loss is seen, alongwith rib cage and spine deformities in females similar to that in severe osteoporosis in females. In males and females premature aging is observed with drastic reduction in sexual abilities and enhanced risk for a serious stroke condition called deep venous thrombosis."
  //       );
  //     },
  //   },

  //   {
  //     value: "Aceruloplasminemia",
  //     diorderType: "",
  //     title: "Aceruloplasminemia",
  //     associatedGene: ["CP"],
  //     icard:
  //       "Aceruloplasminemia is caused by loss of the ceruloplasmin protein that is critical for iron transport and processing across the body.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Aceruloplasminemia",
  //         "Aceruloplasminemia is caused by loss of the ceruloplasmin protein that is critical for iron transport and processing across the body. The condition is characterized by the abnormal accumulation of iron in the brain and various internal organs.The three main findings associated with aceruloplasminemia are retinal degeneration, neurological symptoms and diabetes mellitus. Some individuals with aceruloplasminemia develop mild anemia, which can cause fatigue, weakness, shortness of breath and pale skin. Anemia often occurs before the development of other symptoms commonly associated with aceruloplasminemia."
  //       );
  //     },
  //   },

  //   {
  //     value: "Neuroferritinopathy",
  //     diorderType: "",
  //     title: "Neuroferritinopathy",
  //     associatedGene: ["FTL"],
  //     icard:
  //       "Neuroferritinopathy (NF) belongs to the group of disorders collectively known as neurodegeneration with brain iron accumulation. It causes toxic iron deposition and ferritin inclusions in various brain regions,especially areas important for muscular control.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Neuroferritinopathy",
  //         "Neuroferritinopathy (NF) belongs to the group of disorders collectively known as neurodegeneration with brain iron accumulation. It causes toxic iron deposition and ferritin inclusions in various brain regions,especially areas important for muscular control. Affected individuals gradually display involuntary jerking motions, rhythmic shaking, difficulty coordinating movements, or uncontrolled contraction of muscles for long periods of time. Some may also have difficulty swallowing and speaking. Usually mental abilities remain intact, but in a few cases there can be a gradual decline in thinking and reasoning powers. Challenges in regulating emotions have also been reported."
  //       );
  //     },
  //   },

  //   {
  //     value: "Hemochromatosis",
  //     diorderType: "",
  //     title: "Hemochromatosis",
  //     associatedGene: ["HFE"],
  //     icard:
  //       "Hereditary hemochromatosis (HH) is a general term for several rare genetic disorders that impact iron metabolism due to inadequate function of the homeostatic iron regulator protein or HFE.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Hemochromatosis",
  //         "Hereditary hemochromatosis (HH) is a general term for several rare genetic disorders that impact iron metabolism due to inadequate function of the homeostatic iron regulator protein or HFE. All conditions are characterized by the accumulation of iron in various organs of the body such as the liver, heart and pancreas. Symptoms include joint inflammation and pain, especially in the small joints of the fingers, fatigue, weakness, abdominal pain, unintended weight loss, and an abnormally enlarged liver."
  //       );
  //     },
  //   },

  //   {
  //     value: "Hypochromic microcytic anemia ",
  //     diorderType: "",
  //     title: "Hypochromic microcytic anemia",
  //     associatedGene: ["KCNJ11"],
  //     icard:
  //       "Hypochromic microcytic anemia caused by aberrations in the SLC11A2 gene product which functions as a metal ion transporter. Called the divalent metal transporter 1 (DMT1), the protein is found in all tissues",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Hypochromic microcytic anemia",
  //         "Hypochromic microcytic anemia caused by aberrations in the SLC11A2 gene product which functions as a metal ion transporter. Called the divalent metal transporter 1 (DMT1), the protein is found in all tissues and mediates the transport of dual positive charge metal ions within the cells, where it is requires for a variety of cellular functions. The loss of DMT1 protein function, causes iron buildup in liver with red-blood cells (RBC) unable to access the iron for their normal function. Adolescence and adult onset hyochromic microcytic anemia primarly impacts the liver where a toxic build up of iron causes liver failure. Additional features include decreased RBC production and whateever RBCs are produced are extremely small and pale due to absence of iron. Individuals usually have pale skin and complain of tiredness and slow growth."
  //       );
  //     },
  //   },

  //   {
  //     value: "Iron-refractory iron deficient anaemia",
  //     diorderType: "",
  //     title: "Iron-refractory iron deficient anaemia",
  //     associatedGene: ["TMPRSS6"],
  //     icard: "Iron-refractory iron deficient anaem",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Iron-refractory iron deficient anaemia is an inherited disorder of red blood cells resulting in a sort of anemia that is not relieved by oral supplementation of iron.",
  //         "Iron-refractory iron deficient anaemia is an inherited disorder of red blood cells resulting in a sort of anemia that is not relieved by oral supplementation of iron. Mutations in the gene TMPR66 leads to reduction or loss of function of certain proteins and hormones required for the uptake of iron into haemoglobin. As a result, individuals suffering from this disorder have small, pale red blood cells and show symptoms of anemia such as fatigue, weakness, pale and clammy skin. Symptoms may be partially relieved by parenteral iron supplementation."
  //       );
  //     },
  //   },

  //   {
  //     value: "Very long chain acyl-CoA dehydrogenase deﬁciency",
  //     diorderType: "Lipid and lipoprotein metabolism disorders",
  //     title: "Very long chain acyl-CoA dehydrogenase deﬁciency",
  //     associatedGene: ["KCNJ11"],
  //     icard:
  //       "Very long chain acyl-CoA dehydrogenase deficiency causes a condition where the body is unable to break down specific type of fat molecules to release energy during time of fasting or low blood sugar.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Very long chain acyl-CoA dehydrogenase deficiency",
  //         "Very long chain acyl-CoA dehydrogenase deficiency causes a condition where the body is unable to break down specific type of fat molecules to release energy during time of fasting or low blood sugar. In adults, symptoms include muscle pain due to muscle breakdown. In children and adults, the condition is triggered by periods of fastning, illness or prolonged exerise. There is also a higher risk of liver problems and heart conditions in affected individuals."
  //       );
  //     },
  //   },

  //   {
  //     value: "Metachromatic leukodystrophy (late onset)",
  //     diorderType: "",
  //     title: "Metachromatic leukodystrophy (late onset)",
  //     associatedGene: ["ARSA", "PSAP"],
  //     icard:
  //       "Metachromatic leukodystrophy is a neurological condition caused by the absence or deficiency of the enzyme Arylsulfatase A (from the ARSA gene).",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Metachromatic leukodystrophy (late onset)",
  //         "Metachromatic leukodystrophy is a neurological condition caused by the absence or deficiency of the enzyme Arylsulfatase A (from the ARSA gene). This enzyme helps in recyling of a specific set of fat-protein hybrid molecules called sulfatides, which are an important part of the nerves and the brain.The condition can also be caused by changes/alterations in another gene PSAP, which encodes the Prosaponin protein which activates several sulfatides. Abnormal function of either Arylsulfatase A and Prosaponin causes the destruction of the protective fatty layer (myelin sheath) surrounding the nerves in both the central nervous system and the peripheral nervous system.Adult onset form is the slowest and least severe form, however, it causes behavioral and pyschiatric problems."
  //       );
  //     },
  //   },

  //   {
  //     value: "Neuronal ceroid lipofuscinosis (Kufs disease and Parry type)",
  //     diorderType: "",
  //     title: "Neuronal ceroid lipofuscinosis (Kufs disease and Parry type)",
  //     associatedGene: [
  //       "CLN6",
  //       "CTSF",
  //       "PPT1",
  //       "CLN5",
  //       "CTSD",
  //       "GRN",
  //       "CLN3",
  //       "MFSD8",
  //       "DNAJC5",
  //     ],
  //     icard:
  //       "Neuronal ceroid lipofuscinoses (NCLs) are a progressive, degenerative group of conditions that impact the nervous system. There are several genes and their protein products that can contribute to the diagnosis.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Neuronal ceroid lipofuscinosis (Kufs disease and Parry type)",
  //         "Neuronal ceroid lipofuscinoses (NCLs) are a progressive, degenerative group of conditions that impact the nervous system. There are several genes and their protein products that can contribute to the diagnosis. NCLs as a group are characterized by abnormal accumulation of certain fatty, granular substances (i.e. pigmented lipids,ceroid and lipofuscin) within nerve cells as well as other tissues of the body. This is accompanied by progressive deterioration of certain areas of the brain, neurological impairment, and other characteristic symptoms and physical findings.Adult-onset NCLs (ANCL) are usually charaterized as Kuf or Parry type. Kufs disease is of 2 subtypes: A-type which is associated with seizures & B-type that features motor and movement-abnormalities along with memory loss. The treatment of adult neuronal ceroid lipofuscinosis is directed toward the specific symptoms that are apparent in each individual."
  //       );
  //     },
  //   },

  //   {
  //     value: "Fabry disease",
  //     diorderType: "",
  //     title: "Fabry disease",
  //     associatedGene: ["GLA"],
  //     icard:
  //       "Fabry disease is caused by deficiency or loss of the GLA gene product that encodes an enzyme called alpha-galactosidase-A. This protein is important in the break down of specific complex biomolecules called glycosphingolipids in specialized cell structures called lysosomes.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Fabry disease",
  //         "Fabry disease is caused by deficiency or loss of the GLA gene product that encodes an enzyme called alpha-galactosidase-A. This protein is important in the break down of specific complex biomolecules called glycosphingolipids in specialized cell structures called lysosomes. This disease manifests as progressive renal failure, cardiac disease, cerebrovascular disease, small-fiber peripheral neuropathy, and skin lesions, among other abnormalities."
  //       );
  //     },
  //   },

  //   {
  //     value: "GM1 gangliosidosis (Type III)",
  //     diorderType: "",
  //     title: "GM1 gangliosidosis (Type III)",
  //     associatedGene: ["GLB1"],
  //     icard:
  //       "GM1 gangliosidosis type III is caused by genetic changes that reduce or remove the enzyme beta-galatosidase, which is important for breaking down the complex fat GM1 ganglioside inside special structures in cells called lysosomes.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "GM1 gangliosidosis (Type III)",
  //         "GM1 gangliosidosis type III is caused by genetic changes that reduce or remove the enzyme beta-galatosidase, which is important for breaking down the complex fat GM1 ganglioside inside special structures in cells called lysosomes. This absence of protein therefore leads to a build up of GM1 ganglioside in cells that irreversibly damaging nerve cells in the brain and spinal cord. Type III GM1 gangliosidosis impacts adults. Symptoms include muscle weakness and uncontrolled movements called dystonia. Individuals may also report eye disease impacting the cornea (whites of the eye) and appearance of non-cancerous skin lesions."
  //       );
  //     },
  //   },

  //   {
  //     value: "Tay-Sachs disease",
  //     diorderType: "",
  //     title: "Tay-Sachs disease",
  //     associatedGene: ["HEXA"],
  //     icard:
  //       "Tay-Sachs Disease is caused by defective action of the enzyme called beta-hexosaminidase A which is coded by the HEXA gene.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Tay-Sachs disease",
  //         "Tay-Sachs Disease is caused by defective action of the enzyme called beta-hexosaminidase A which is coded by the HEXA gene. This enzyme is responsible for the break down of a specific type of complex fat molecule called GM2 ganglioside in specialized recycling sites called lysosomes inside the cells. In the absence of normal function of beta-hexsaminidase, there is a toxic build up of GM2 gangliosides in nerve cells, which eventually leads to the death of these cells. Features of late-onset Tay-Sachs disease that appear in adulthood include muscle weakness, loss of muscle coordination, speech problems, and psychiatric symptoms. These signs and symptoms vary widely among people with late-onset forms of Tay-Sachs disease."
  //       );
  //     },
  //   },

  //   {
  //     value: "Sandhoff disease",
  //     diorderType: "",
  //     title: "Sandhoff disease",
  //     associatedGene: ["HEXB"],
  //     icard:
  //       "Sandhoff disease is a rare, progressive condition that destroys nerve cells in the brain and spinal cord.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Sandhoff disease",
  //         "Sandhoff disease is a rare, progressive condition that destroys nerve cells in the brain and spinal cord. It is caused by abnormal functioning of HEXAB gene product that contributes to the synthesis of the beta-hexosaminidase A which is instrumental in the break down of a fatty substance called GM2 ganglioside in in specialised compartment called lysosome in cells. Absence of normal function of this protein hence causes toxic build up of GM2 gangliosides in nerve cells, which eventually leads to the death of these cells. This is classified into three major types based on the age at which signs and symptoms first appear: infantile, juvenile, and adult. Adult Sandhoff disease is characterized by problems with movement and psychiatric problems."
  //       );
  //     },
  //   },

  //   {
  //     value: "PLA2G6-related dystonia-parkinsonism (atypical)",
  //     diorderType: "",
  //     title: "PLA2G6-related dystonia-parkinsonism (atypical)",
  //     associatedGene: ["PLA2G6"],
  //     icard:
  //       "PLA2G6-related dystonia-parkinsonism (atypical) is caused by alteration of the phospholipase A2 group VI enzyme (PLA2G6) required in the body to break down fats properly. The disorder has two types of inherited forms that can manifest from adolescence to adulthood",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "PLA2G6-related dystonia-parkinsonism (atypical)",
  //         "PLA2G6-related dystonia-parkinsonism (atypical) is caused by alteration of the phospholipase A2 group VI enzyme (PLA2G6) required in the body to break down fats properly. The disorder has two types of inherited forms that can manifest from adolescence to adulthood, other than the infantile variety. PLA2G6 enzyme's absence has important consequences for nerve and brain function. Atypical neuroaxonal dystrophy can set in during adolescence and early adulthood, with involuntary muscle contractions, muscle stiffness, difficulty in speaking, eye muscle weakness, unstable gait, and neuropsychiatric symptoms which resemble autism or attention deficit hyperactive disorder .PLA2G6 related movement impairements and related Parkinsonism features has a later age of onset, with gait changes, Parkinsonism and psychiatric changes such as depression and aggression."
  //       );
  //     },
  //   },

  //   {
  //     value: "Niemann Pick Disease Type C",
  //     diorderType: "",
  //     title: "Niemann Pick Disease Type C",
  //     associatedGene: ["NPC1", "NPC2"],
  //     icard:
  //       "Niemann Pick disease type C is caused by loss of function of NPC1 and NPC2 gene products, which are transporter proteins required for moving cholesterol inside cells from the bloodstream for further processing.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Niemann Pick Disease Type C",
  //         "Niemann Pick disease type C is caused by loss of function of NPC1 and NPC2 gene products, which are transporter proteins required for moving cholesterol inside cells from the bloodstream for further processing.This leads to the toxic build up of these substances within various tissues of the body, including brain tissue.Neurologic and psychiatric symptoms develop slowly over time, and thus feature later on in life. Common late onset may include cerebellar ataxia, dysarthria, dysphagia, cognitive impairment, and other movement disorders such as dystonia or tremor.Treatment is directed toward the specific symptoms apparent in each individual."
  //       );
  //     },
  //   },

  //   {
  //     value: "Mucopolysaccharidoses type 1 late onset (Scheie Sydrome)",
  //     diorderType: "Lysosomal Storage Disease",
  //     title: "Mucopolysaccharidoses type 1 late onset (Scheie Sydrome)",
  //     associatedGene: ["GLB1"],
  //     icard:
  //       "Mucopolysaccharidoses type 1 late onset or Scheie Sydrome is caused by the defeciency of the enzyme alpha-L-iduronidase which is present in special cellular structures called lysosomes that carry out breakdown and reclycing of complex biomolecules.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Mucopolysaccharidoses type 1 late onset (Scheie Sydrome)",
  //         "Mucopolysaccharidoses type 1 late onset or Scheie Sydrome is caused by the defeciency of the enzyme alpha-L-iduronidase which is present in special cellular structures called lysosomes that carry out breakdown and reclycing of complex biomolecules. Iduronidase is repsonsible for the breakdown of specific complex sugar molecules called glycosaminoglycans which are present through out the body especially in fluids that lubricate joints. Scheie syndrome patients usually have vision related complaints including cloudy corneas (whites of eyes), degeneration of the retina (photosensitive layer at the back of the eye) and build up of fluid pressure in eye chambers. They may also show mild mental impairment or heart valve disease along with deformities in wrists and other joint stiffness."
  //       );
  //     },
  //   },

  //   {
  //     value: "Fabry disease",
  //     diorderType: "",
  //     title: "Fabry disease",
  //     associatedGene: ["GLB1"],
  //     icard:
  //       "Fabry disease is caused by deficiency or loss of the GLA gene that encodes an enzyme called alpha-galactosidase-A . This protein is important in the break down of specific complex biomolecules called glycosphingolipids in specialized cell structures called the lysosome.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Fabry disease",
  //         "Fabry disease is caused by deficiency or loss of the GLA gene that encodes an enzyme called alpha-galactosidase-A . This protein is important in the break down of specific complex biomolecules called glycosphingolipids in specialized cell structures called the lysosome.This disease manifests as progressive renal failure, cardiac disease, cerebrovascular disease, small-fiber peripheral neuropathy, and skin lesions, among other abnormalities."
  //       );
  //     },
  //   },

  //   {
  //     value: "Dopa-responsive dystonia, classic Segawa",
  //     diorderType: "Neurotransmitter metabolism disorders",
  //     title: "Dopa-responsive dystonia, classic Segawa",
  //     associatedGene: ["GCH1"],
  //     icard:
  //       "Segawa Syndrome of Dopa-responsive dystonia is caused by alteration of the GCH-1 gene which encodes the enzyme called guanosine triphosphate cyclohydrolase 1 (GTPCH1). This enzyme is important to disrupt dopamine production, which is a communication/signaling chemical secreted by nerve cells.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Dopa-responsive dystonia, classic Segawa",
  //         "Segawa Syndrome of Dopa-responsive dystonia is caused by alteration of the GCH-1 gene which encodes the enzyme called guanosine triphosphate cyclohydrolase 1 (GTPCH1). This enzyme is important to disrupt dopamine production, which is a communication/signaling chemical secreted by nerve cells. It serves as a messenger to mediate movement and mood control. Dystonia is a major feature of this condition, which means involuntary muscle contractions that twist or lock the body into abnormal and painful postures for a long time. Though majority of the patients are diagnosed at childhood, when the onset of Segawa syndrome is during adolescence or adulthood, the symptoms may resemble those found in Parkinson’s disease. This includes shakes or tremors, slowness of movement and an inability to remain in a stable or balanced position. Dystonia of a general limb or parts of the body is not seen, though localized dystonia or toes, wrists etc may occur from time to time. Most people respond very well to treatment with levo-dopa but the intermittent nature of symptom severity delays diagnosis and care."
  //       );
  //     },
  //   },

  //   {
  //     value:
  //       "Dopamine transporter deficiency syndrome (DTDS, atypical late onset type)",
  //     diorderType: "",
  //     title:
  //       "Dopamine transporter deficiency syndrome (DTDS, atypical late onset type)",
  //     associatedGene: ["SLC6A3"],
  //     icard:
  //       "Dopamine transporter deficiency syndrome (DTDS, atypical late onset type) is caused by deficiency or abnormal functioning of the transporter protein dopamine transporter. Dopamine is a chemical messenger that relays signals from one neuron to another",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Dopamine transporter deficiency syndrome (DTDS, atypical late onset type)",
  //         "Dopamine transporter deficiency syndrome (DTDS, atypical late onset type) is caused by deficiency or abnormal functioning of the transporter protein dopamine transporter. Dopamine is a chemical messenger that relays signals from one neuron to another and extremely important for mental abilities and control of movement.Atypical DTDS shows features akin to Parkinson's disease, i.e.- tremors or uncontrolled shaking, slowness of movement and an inability to remain in a stable or balanced position."
  //       );
  //     },
  //   },

  //   {
  //     value: "Zellweger spectrum disorders",
  //     diorderType: "Peroxisomal Disorders",
  //     title: "Zellweger spectrum disorders",
  //     associatedGene: ["PEX6", "PEX10", "PEX12"],
  //     icard:
  //       "Zellweger Spectrum Disorders (ZSD) is a group of disorders characterized by the failure of the body to produce peroxisomes that function properly. Peroxisomes are important cellualr compartments that are important for metabolic reactions especially those related to energy production.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Zellweger spectrum disorders",
  //         "Zellweger Spectrum Disorders (ZSD) is a group of disorders characterized by the failure of the body to produce peroxisomes that function properly. Peroxisomes are important cellualr compartments that are important for metabolic reactions especially those related to energy production. Adults rarely show ZSD symptoms. When they do, symptoms show up as hearing loss and vision problems and mild levels of mental disability."
  //       );
  //     },
  //   },

  //   {
  //     value: "Fanconi's Anemia",
  //     diorderType: "Purine and pyramidine metabolism",
  //     title: "Fanconi's Anemia",
  //     associatedGene: ["FANCC"],
  //     icard:
  //       "Fanconi anemia is caused by mutations in a cluster of genes which mediate DNA repair. FANCC protein is a part of the DNA repair pathway that especially functions to correct instances of strand pairing errors in DNA.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Fanconi's Anemia",
  //         "Fanconi anemia is caused by mutations in a cluster of genes which mediate DNA repair. FANCC protein is a part of the DNA repair pathway that especially functions to correct instances of strand pairing errors in DNA. Affected individuals usually have abnormal bone marrow function, causing a lowered level of all types of blood cells. Adults complain of generalized tiredness, lowered immunity and clotting problems. They may not always show light- or dark-colored skin patches, but usually have reduced fertility. Many individuals develop specific types of cancers including acute myeloid leukemia (AML) and squamous cell carcinoma"
  //       );
  //     },
  //   },

  //   {
  //     value:
  //       "SANDO syndrome (Sensory Ataxic Neuropathy, Dysarthria, Opthalmoparesis)",
  //     diorderType: "",
  //     title:
  //       "SANDO syndrome (Sensory Ataxic Neuropathy, Dysarthria, Opthalmoparesis)",
  //     associatedGene: ["POLG"],
  //     icard:
  //       "SANDO syndrome is caused by defects in the polymerase gamma protein, important for the copying and maintenance of DNA inside mitochondria which are the energy-generating factories of the cell.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "SANDO syndrome (Sensory Ataxic Neuropathy, Dysarthria, Opthalmoparesis)",
  //         "SANDO syndrome is caused by defects in the polymerase gamma protein, important for the copying and maintenance of DNA inside mitochondria which are the energy-generating factories of the cell. Adult onset cases usually show deficits in skeletal muscle and peripheral nerve tissue causing loss of control of gait and voluntary limb movement, often in response to some sensory information. There is accompanying pain in the far reaching nerves of the fingers, toes and limbs. A section of affected individuals also develop loss of control of eye movements. Some may have pre-existing undetected levels of impact to mental abilities which likely was present from childhood."
  //       );
  //     },
  //   },

  //   {
  //     value: "Spinal muscular atrophy-4 (late onset)",
  //     diorderType: "Motor Neuron Disease",
  //     title: "Spinal muscular atrophy-4 (late onset)",
  //     associatedGene: ["SMN1"],
  //     icard:
  //       "Spinal muscular atrophy is a set of motor neuron degenerative disorder that is caused by loss of SMN1 gene product which is critical for the function of nerve cells that control muscle movements.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Spinal muscular atrophy-4 (late onset)",
  //         "Spinal muscular atrophy is a set of motor neuron degenerative disorder that is caused by loss of SMN1 gene product which is critical for the function of nerve cells that control muscle movements. The type of SMA loss dictates the severity of the condition and age of onset. SMA type 4 occurs in less than 1% of people. Symptoms are least severe than for all other subtypes with first features appearing usually after 35 years of age. All motor developmental milestones are achieved and most individuals with SMA type 4 can walk throughout their life and have normal life expectancy."
  //       );
  //     },
  //   },

  //   {
  //     value: "2-methyl acyl-CoA racemase deficiency (AMACR deficiency)",
  //     diorderType: "",
  //     title: "2-methyl acyl-CoA racemase deficiency (AMACR deficiency)",
  //     associatedGene: ["AMACR"],
  //     icard:
  //       "AMACR deficiency is caused by the altered function of the enzyme Alpha-methylacyl-CoA racemase which is important for the processing and breakdown of cholestrol and bile acids. The disorder causes a variety of neurological problems that begin in adulthood and slowly get worse.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "2-methyl acyl-CoA racemase deficiency (AMACR deficiency)",
  //         "AMACR deficiency is caused by the altered function of the enzyme Alpha-methylacyl-CoA racemase which is important for the processing and breakdown of cholestrol and bile acids. The disorder causes a variety of neurological problems that begin in adulthood and slowly get worse. People with AMACR deficiency may have a gradual loss in intellectual function, seizures, and migraines."
  //       );
  //     },
  //   },

  //   {
  //     value: "Cerebrotendinous xanthomatosis",
  //     diorderType: "",
  //     title: "Cerebrotendinous xanthomatosis",
  //     associatedGene: ["CYP27A1"],
  //     icard:
  //       "Cerebrotendinous xanthomatosis is caused by the lack of the enzyme sterol 27-hydroxylase that is required to process cholestrol to a specific bile acid type for degradation.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Cerebrotendinous xanthomatosis",
  //         "Cerebrotendinous xanthomatosis is caused by the lack of the enzyme sterol 27-hydroxylase that is required to process cholestrol to a specific bile acid type for degradation. Affected individuals have a build up of cholestrol and by-product cholestenol in nerve cells causing brain, spinal cord, eye-damage and abnormally functioning arteries and blood vessels. Though symptoms can be evident during childhood, the neurological symptoms are often first seen in early adults. These include depression, agitation, hallucination, and suicide attempts or uncontrolled muscle ticks or motor mannerism, shaking and tremors, seizures and tingling nerve pain. Bone and heart conditions usually develop after the third decade."
  //       );
  //     },
  //   },

  //   {
  //     value:
  //       "Congenital adrenal hyperplasia due to 21-hydroxylase deficiency (non-classic)",
  //     diorderType: "",
  //     title:
  //       "Congenital adrenal hyperplasia due to 21-hydroxylase deficiency (non-classic)",
  //     associatedGene: ["CYP21A2"],
  //     icard:
  //       "Congenital adrenal hyperplasia (CAH) caused by the shortage of the enzyme 21-hydroxylase deficiency due to alterations in the CYP21A2 gene. The condition leads to underproduction of critical hormones of adrenal glands needed to maintain salt balance, develop proper sexual characters and mediate the body's response to stress.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Congenital adrenal hyperplasia due to 21-hydroxylase deficiency (non-classic)",
  //         "Congenital adrenal hyperplasia (CAH) caused by the shortage of the enzyme 21-hydroxylase deficiency due to alterations in the CYP21A2 gene. The condition leads to underproduction of critical hormones of adrenal glands needed to maintain salt balance, develop proper sexual characters and mediate the body's response to stress. There are two forms of CAH: classic CAH that occurs in children and non-classic that appears in adults. Non-classic CAH's symptoms are milder ranging from irregular menstrual health and fertility in females, early onset of puberty in males and females and some blood pressure related problems."
  //       );
  //     },
  //   },

  //   {
  //     value:
  //       "Dopamine transporter deficiency syndrome (DTDS, atypical late onset type)",
  //     diorderType: "",
  //     title:
  //       "Dopamine transporter deficiency syndrome (DTDS, atypical late onset type)",
  //     associatedGene: ["SLC6A3"],
  //     icard:
  //       "Dopamine transporter deficiency syndrome (DTDS, atypical late onset type) is caused by deficiency or abnormal functioning of the transporter protein dopamine transporter.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Dopamine transporter deficiency syndrome (DTDS, atypical late onset type)",
  //         "Dopamine transporter deficiency syndrome (DTDS, atypical late onset type) is caused by deficiency or abnormal functioning of the transporter protein dopamine transporter. Dopamine is a chemical messenger that relays signals from one neuron to another and extremely important for mental abilities and control of movement.Atypical DTDS shows features akin to Parkinson's disease, i.e.- tremors or uncontrolled shaking, slowness of movement and an inability to remain in a stable or balanced position."
  //       );
  //     },
  //   },

  //   {
  //     value:
  //       "SANDO syndrome (Sensory Ataxic Neuropathy, Dysarthria, Opthalmoparesis)",
  //     diorderType: "",
  //     title:
  //       "SANDO syndrome (Sensory Ataxic Neuropathy, Dysarthria, Opthalmoparesis)",
  //     associatedGene: ["POLG"],
  //     icard:
  //       "SANDO syndrome is caused by defects in the polymerase gamma protein, important for the copying and maintenance of DNA inside mitochondria which are the energy-generating factories of the cell.Adult onset cases usually show deficits in skeletal muscle and peripheral nerve tissue causing loss of control of gait and voluntary limb movement, often in response to some sensory information.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "SANDO syndrome (Sensory Ataxic Neuropathy, Dysarthria, Opthalmoparesis)",
  //         "SANDO syndrome is caused by defects in the polymerase gamma protein, important for the copying and maintenance of DNA inside mitochondria which are the energy-generating factories of the cell.Adult onset cases usually show deficits in skeletal muscle and peripheral nerve tissue causing loss of control of gait and voluntary limb movement, often in response to some sensory information. There is accompanying pain in the far reaching nerves of the fingers, toes and limbs. A section of affected individuals also develop loss of control of eye movements. Some may have pre-existing undetected levels of impact to mental abilities which likely was present from childhood."
  //       );
  //     },
  //   },

  //   {
  //     value: "X-linked adrenoleukodystrophy",
  //     diorderType: "Sterol Metabolism",
  //     title: "X-linked adrenoleukodystrophy",
  //     associatedGene: ["ABCD1"],
  //     icard:
  //       "Adrenoleukodystrophy (ALD) is caused by an altered or absent adrenoleukodystrophy protein, which is crucial for transporting fat molecules into specific cellular compartments for breakdown.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "X-linked adrenoleukodystrophy",
  //         "Adrenoleukodystrophy (ALD) is caused by an altered or absent adrenoleukodystrophy protein, which is crucial for transporting fat molecules into specific cellular compartments for breakdown. Adrenoleukodystrophy causes a range of conditions where there is build-up of fat molecules in the brain and adrenal glands of the body. Late onset conditions show up as deficiency in key hormones secreted by adrenal gland causing fatigue,weight loss, vomiting, gastrointestinal issues, general weakness and low blood pressure and low blood sugar. Some patients also display muscle stiffness and pain."
  //       );
  //     },
  //   },

  //   {
  //     value: "Congenital adrenal hypoplasia",
  //     diorderType: "",
  //     title: "Congenital adrenal hypoplasia",
  //     associatedGene: ["NR0B1"],
  //     icard:
  //       "Congenital adrenal hypoplasia (CAH) is caused by the loss of functional DAX1 protein that is the product of the gene NR0B1.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Congenital adrenal hypoplasia",
  //         "Congenital adrenal hypoplasia (CAH) is caused by the loss of functional DAX1 protein that is the product of the gene NR0B1. DAX1 is important in the development of endocrine (hormone secreting) tissues found in the brain, gonads and adrenal glands placed above the kidney. Adults diagnosed later in life are thought to have non-classic form which includes symptoms like irregular menstrual periods, or not having any at all, and problems getting pregnant in females. Females tend to develop excessive facial hair, a deep voice, severe acne and unregulated levels of the hormone cortisol. In males, redued ferility, severe acne, rapid aging of bones and premature puberty is reported."
  //       );
  //     },
  //   },

  //   {
  //     value: "Wilson's disease",
  //     diorderType: "Trace element and mineral metabolism",
  //     title: "Wilson's disease",
  //     associatedGene: ["ATP7B"],
  //     icard:
  //       "Wilson's disease is caused by the deficiency of the ATP7B gene product that is a copper transporter within the body. This protein not only transports copper to parts of the body where there is requirement for copper in many cellular functions, but also mediates the excretion of excess copper from the body.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Wilson's disease",
  //         "Wilson's disease is caused by the deficiency of the ATP7B gene product that is a copper transporter within the body. This protein not only transports copper to parts of the body where there is requirement for copper in many cellular functions, but also mediates the excretion of excess copper from the body. Hence Wilson's disease results in the build up of copper which can have widespread adverse effects. Liver disease is frequently the presenting sign, but psychiatric and neurological symptoms including movement disorders are also frequent presentations. The Kayser-Fleischer ring, copper deposits that form a ring in the cornea, is the ophthalmological hallmark of Wilson’s disease. Abnormalities of eye movements are frequently present. Impaired vertical, but sometimes also horizontal pursuit, selective slowing of downward saccades, and dysmetria of saccades are all reported. Gaze distractibility has also been described in which patients cannot fix their eyes on a stationary or moving object for more than a few seconds without being distracted by other stimuli. Treatment is possible with chelation therapy."
  //       );
  //     },
  //   },

  //   {
  //     value: "Hypophosphatasia, adult",
  //     diorderType: "",
  //     title: "Hypophosphatasia, adult",
  //     associatedGene: ["ALPL"],
  //     icard:
  //       "Adult onset hypophosphatasia is caused by moderate deficiency of the enzyme non-specific tissue alkaline phosphatase which is important in maintaining the mineral density and integrity of bones and cartilage.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Hypophosphatasia, adult",
  //         "Adult onset hypophosphatasia is caused by moderate deficiency of the enzyme non-specific tissue alkaline phosphatase which is important in maintaining the mineral density and integrity of bones and cartilage. Affected people complain of frequent fractures, muscle pains, premature loss of teeth and range of orthopedic and kidney problems. In adults, this condition is frequently misdiagnosed and treatment with calcium and Vit D supplements worsens the condition."
  //       );
  //     },
  //   },

  //   {
  //     value: "Syndrome of liver cirrhosis, dystonia and hypermanganesemia",
  //     diorderType: "",
  //     title: "Syndrome of liver cirrhosis, dystonia and hypermanganesemia",
  //     associatedGene: ["SLC30A10"],
  //     icard:
  //       "Hypermanganesemia with dystonia I is an condition resulting from alterations in SLC 301 protein that is a key carrier of Manganese and export of this trace element out of cells to prevent toxicity. Loss of this transporter is associated with uncontrolled contractions of limbs",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Syndrome of liver cirrhosis, dystonia and hypermanganesemia",
  //         "Hypermanganesemia with dystonia I is an condition resulting from alterations in SLC 301 protein that is a key carrier of Manganese and export of this trace element out of cells to prevent toxicity. Loss of this transporter is associated with uncontrolled contractions of limbs, tremors and shakes with loss of control of voluntary movement with features resembling Parkinson's disease."
  //       );
  //     },
  //   },

  //   {
  //     value: "Acrodermatitis Enteropathica",
  //     diorderType: "",
  //     title: "Acrodermatitis Enteropathica",
  //     associatedGene: ["SLC39A4"],
  //     icard:
  //       "Acrodermatitis enteropathica is caused by alterations in the SLC39A4 gene product that encodes for a transporter protein which helps absorb Zinc, an important micronutrient, from the intestines.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Acrodermatitis Enteropathica",
  //         "Acrodermatitis enteropathica is caused by alterations in the SLC39A4 gene product that encodes for a transporter protein which helps absorb Zinc, an important micronutrient, from the intestines. Insufficient absorption of Zinc leads to multiple gastrointestinal and skin related issues, such as diarrhea, lack of appetite, irritability, inflammation, cracking and blistering of skin around the mouth, anus, eyes, elbows, knees etc, deformed nails with inflamed skin around the cuticles, hair loss from the head, eyelashes and eyebrows, and psychiatric disturbances such as lethargy, anorexia or depression. Lifelong zinc supplementation can be used to treat and manage the symptoms."
  //       );
  //     },
  //   },

  //   {
  //     value: "Spinocerebellar ataxia type 10",
  //     diorderType: "Transport and channel metabolism",
  //     title: "Spinocerebellar ataxia type 10",
  //     associatedGene: ["ANO10"],
  //     icard:
  //       "Spinocerebellar ataxia type 10 is caused by defect or loss of Anotacmin 10 protein which is a negatively charged ion transport protein with a secondary role in maintaining the integrity of inner cellular structures.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Spinocerebellar ataxia type 10",
  //         "Spinocerebellar ataxia type 10 is caused by defect or loss of Anotacmin 10 protein which is a negatively charged ion transport protein with a secondary role in maintaining the integrity of inner cellular structures. Spinocerebellar ataxia is a group of neurodegenerative disorders in which there is degradation of gait, voluntary movements, and limb coordination. Additional features may include seizures, low muscle tone, and mental disability."
  //       );
  //     },
  //   },

  //   {
  //     value: "Kufor Rakeb disease",
  //     diorderType: "",
  //     title: "Kufor Rakeb disease",
  //     associatedGene: ["ATP13A2"],
  //     icard:
  //       "Kufor rakeb syndrome is an inherited early onset form of Parkinson's disease caused by mutations to the gene ATP13A2. The gene codes for enzymes that are important for energy production, maintaining the balance of zinc and manganese in, as well as clearing toxic waste products from, cells of the body.",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Kufor Rakeb disease",
  //         "Kufor rakeb syndrome is an inherited early onset form of Parkinson's disease caused by mutations to the gene ATP13A2. The gene codes for enzymes that are important for energy production, maintaining the balance of zinc and manganese in, as well as clearing toxic waste products from, cells of the body.  When these mechanisms are compromised, Parkinson's disease like symptoms start developing from adolescence or early adulthood - like slow movement, tremors, loss of posture and balance, muscular rigidity, uncoordinated movements and speech difficulties. Additional symptoms such as partial or total paralysis of the legs, impaired eye movement, and iron accumulation in the brain are also seen. Neurological symptoms include degeneration of certain brain structures, intellectual disabilities, visual or auditory hallucinations, and dementia."
  //       );
  //     },
  //   },

  //   {
  //     value: "Retinitis Pigmentosa due to BBS2",
  //     diorderType: "",
  //     title: "Retinitis Pigmentosa due to BBS2",
  //     associatedGene: ["BBS2"],
  //     icard:
  //       "Retinitis Pigmentosa represents a large set of progressive vision degeneration disorders caused by a variety of gene mutations. ",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Retinitis Pigmentosa due to BBS2",
  //         "Retinitis Pigmentosa represents a large set of progressive vision degeneration disorders caused by a variety of gene mutations. This variant is caused by abnormal function of the BBS2 protein which in a combination with other related proteins regulates ciliary movements in a variety of cells in the body. Cilia are a special structure in cells lining canal/channels of the body and help in fluid flow and are critical to normal eye function. Affected individuals initially complain of vision loss in dim lighting conditions, followed by increasing impact on their ability to see peripheral and main objects."
  //       );
  //     },
  //   },

  //   {
  //     value: "Myasthenic syndrome 4A (adult)",
  //     diorderType: "",
  //     title: "Myasthenic syndrome 4A (adult)",
  //     associatedGene: ["CHRNE"],
  //     icard:
  //       "Myasthenic syndrome type 4A is caused by loss of a part of the acetylcholine receptor protein that is critical for nervous and muscular co-ordination that underlies all body movements. ",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Myasthenic syndrome 4A (adult)",
  //         "Myasthenic syndrome type 4A is caused by loss of a part of the acetylcholine receptor protein that is critical for nervous and muscular co-ordination that underlies all body movements. Conditions that are detected in early adulthood onwards usually present as rapid onset and progression of muscle weakness, gait irregulalrities and lazy eye."
  //       );
  //     },
  //   },

  //   {
  //     value:
  //       "Dopamine transporter deficiency syndrome (DTDS, atypical late onset type)",
  //     diorderType: "",
  //     title:
  //       "Dopamine transporter deficiency syndrome (DTDS, atypical late onset type)",
  //     associatedGene: ["SLC6A3"],
  //     icard:
  //       "Dopamine transporter deficiency syndrome (DTDS, atypical late onset type) is caused by deficiency or abnormal functioning of the transporter protein dopamine transporter. ",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Dopamine transporter deficiency syndrome (DTDS, atypical late onset type)",
  //         "Dopamine transporter deficiency syndrome (DTDS, atypical late onset type) is caused by deficiency or abnormal functioning of the transporter protein dopamine transporter. Dopamine is a chemical messenger that relays signals from one neuron to another and extremely important for mental abilities and control of movement. Atypical DTDS shows features akin to Parkinson's disease, i.e.- tremors or uncontrolled shaking, slowness of movement and an inability to remain in a stable or balanced position."
  //       );
  //     },
  //   },

  //   {
  //     value: "Basal ganglia disease, biotin-responsive",
  //     diorderType: "",
  //     title: "Basal ganglia disease, biotin-responsive",
  //     associatedGene: ["SLC19A3"],
  //     icard:
  //       "Basal ganglia disease, biotin-responsive (BTBGD) is an inherited disorder of metabolism caused by defects in the gene SLC19A3 gene which encodes a transporter protein involved in utilizing thiamine, a form of Vitamin B. Thiamine is especially required for nervous and muscular function. ",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Basal ganglia disease, biotin-responsive",
  //         "Basal ganglia disease, biotin-responsive (BTBGD) is an inherited disorder of metabolism caused by defects in the gene SLC19A3 gene which encodes a transporter protein involved in utilizing thiamine, a form of Vitamin B. Thiamine is especially required for nervous and muscular function. This can have serious effects on metabolic processes in the brain and muscles, sometimes even resulting in coma, paralysis or death. Symptoms are often triggered by some kind of physiological stress such as infection or fever. BTBGD may also show up in adults in the second decade of life, present with epilepsy, involuntary eye movements and poor muscular co-ordination. Symptoms may recede if patients are supplemented externally with thiamine and biotin."
  //       );
  //     },
  //   },

  //   {
  //     value: "Hermansky-Pudlak syndrome 1",
  //     diorderType: "",
  //     title: "Hermansky-Pudlak syndrome 1",
  //     associatedGene: ["HPS1"],
  //     icard:
  //       "Hermansky-Pudlack Syndrome type 1 is caused due to a defect in the gene product of the HPS-1 gene. This protein is a part of complex that helps the formation or movement of specific recyling bodies inside cells called lysosome-like vescicles. ",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Hermansky-Pudlak syndrome 1",
  //         "Hermansky-Pudlack Syndrome type 1 is caused due to a defect in the gene product of the HPS-1 gene. This protein is a part of complex that helps the formation or movement of specific recyling bodies inside cells called lysosome-like vescicles. These structures are especially enriched in pigment producing cells like those in the iris of the eye, platelets, skin and hair. All forms of this syndrome have characteristic features of decreased pigmentation in eyes, hair, skin, and clotting problems due to abnormal platelets causing prolonged bleeding due to small injuries. Individuals also report bleeding gums, vision problems, enlarged colon and scarring of lung tissue, especially in HPS1."
  //       );
  //     },
  //   },

  //   {
  //     value: "Hermansky-Pudlak syndrome 3",
  //     diorderType: "",
  //     title: "Hermansky-Pudlak syndrome 3",
  //     associatedGene: ["HPS3"],
  //     icard:
  //       "Hermansky-Pudlack Syndrome type 3 is caused due to a defect in the gene product of the HPS 3 gene, which is a part of a complex that helps the formation or movement of specific recyling bodies inside cells called lysosome-like vescicles.  ",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Hermansky-Pudlak syndrome 3",
  //         "Hermansky-Pudlack Syndrome type 3 is caused due to a defect in the gene product of the HPS 3 gene, which is a part of a complex that helps the formation or movement of specific recyling bodies inside cells called lysosome-like vescicles. These structures are especially enriched in pigment producing cells like those in the iris of the eye, platelets, skin and hair. All forms of this syndrome have characteristic features of decreased pigmentation in eyes, hair, skin, and clotting problems due to abnormal platelets causing prolonged bleeding due to small injuries. The individuals also report bleeding gums, vision problems, enlarged and inflamed colon. HPS 3 is not associated with lung scarring."
  //       );
  //     },
  //   },
  //   {
  //     value: "Basal ganglia disease, biotin-responsive",
  //     diorderType: "Vitamin, Mineral and co-factor metabolism",
  //     title: "Basal ganglia disease, biotin-responsive",
  //     associatedGene: ["SLC19A3"],
  //     icard:
  //       "Basal ganglia disease, biotin-responsive (BTBGD) is an inherited disorder of metabolism caused by defects in the gene SLC19A3 gene which encodes a transporter protein involved in utilizing thiamine, a form of Vitamin B. Thiamine is especially required for nervous and muscular function. ",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Basal ganglia disease, biotin-responsive",
  //         "Basal ganglia disease, biotin-responsive (BTBGD) is an inherited disorder of metabolism caused by defects in the gene SLC19A3 gene which encodes a transporter protein involved in utilizing thiamine, a form of Vitamin B. Thiamine is especially required for nervous and muscular function. This can have serious effects on metabolic processes in the brain and muscles, sometimes even resulting in coma, paralysis or death. Symptoms are often triggered by some kind of physiological stress such as infection or fever. BTBGD may also show up in adults in the second decade of life, present with epilepsy, involuntary eye movements and poor muscular co-ordination. Symptoms may recede if patients are supplemented externally with thiamine and biotin."
  //       );
  //     },
  //   },
  // ];

  const metaTypes = curation.getCurationData('In-born Errors of Metabolism Report')

  useEffect(() => {
    topRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  function onPanelClose() {
    setPanelOpen(false);
  }

  const navgate = useNavigate();
  const naviagteToContact = () => {
    navgate("/contact");
  };

  function openPopup(data) {
    PopService.open("In-born Errors of Metabolism Report", {
      data,
    });
  }

  return (
    <div className="pageWrapper2">
      <div className="innerWrapper container-fluid">
        <div ref={topRef}></div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadForMobile">
            <li className="breadcrumb-item">
              <a href={"#/dashboard" + location.search}>Dashboard</a>
            </li>
            <li className="breadcrumb-item marginTop">
              <a href={"#/dashboard" + location.search}>Adult Onset</a>
            </li>
            <li className="breadcrumb-item marginTop">
              <a href={"#/metabolism" + location.search}>Metabolism</a>
            </li>
            <li
              className="breadcrumb-item active bg1 marginTop"
              aria-current="page"
            >
              Results
            </li>
          </ol>
        </nav>

        {isPanelOpen && (
          <Sidepanel open={isPanelOpen} onCloseCallback={onPanelClose} />
        )}
        <div className="card mt-1 p-3 p-md-5 pt-md-2 bxShdow bdrNone cardTransparent">
          <Row>
            <Col>
              <h1 className="ff1 fs30 fw600 text-muted pt-3">
                In-born Errors of Metabolism Results
              </h1>
            </Col>
          </Row>

          <Row>
            <Col>
              <h4 className="fs20 pb-4">
                {
                  iemFinding?.num_observations_of_significance > 0 ? (
                    <>
                      Your results have
                      <span
                        className={

                          iemFinding?.num_observations_of_significance !== 0
                            ? "variantHighlightRed"
                            : "variantHighlightGreen"
                        }
                      >
                        {
                          iemFinding?.num_observations_of_significance}
                      </span>
                      observation/s of significance.
                    </>
                  ) :
                    <> No observation/s of significance were detected.</>
                }
                {
                  <NotesAndLimitationsModal curationData={metaTypes} draftOrder={draftOrder} />
                }
              </h4>
            </Col>
          </Row>


          {
            iemFinding?.num_observations_of_significance > 0 ? (<>
              <Row className="mt-5">
                <Col className="d-flex">
                  <h2 className="ff1 fs24 fw700">
                    Summary of Observations of Significance
                  </h2>
                </Col>
              </Row>

              <Row>
                <Col style={{ padding: "0px 16px" }}>
                  <div className="table-responsive border border-info">
                    <Table className="table table-striped table-responsive report-table mb-0 summary-report-table">
                      <thead>
                        <tr className="fw600 bg-info bg-gradient bg-opacity-50 text-nowrap">
                          <td style={{ width: "25%" }} title='Individual disorders bundled together due to similar clinical features or biological pathway of origin'>Broad Disorder Type <sup className="small-text">&#9432;</sup></td>
                          <td style={{ width: "20%" }} title='Specific clinical disease identified by the variant seen in the gene associated with the cause of disease'>Specific Disorder <sup className="small-text">&#9432;</sup></td>
                          <td style={{ width: "10%" }} title='Type of chromosome on which a gene is located (autosomal or X linked) and whether the variant is likely to manifest as disease (dominant or recessive). Eg; An “Autosomal Dominant” or “AD” allocation refers to the gene being autosomal in origin and the nature of the variant being “dominant” or “likely disease causing”'>Inheritance <sup className="small-text">&#9432;</sup></td>
                          <td style={{ width: "10%" }} title='Number of copies of a gene variant. Heterozygous refers to a single copy of the gene variant and Homozygous refers to both copies of a gene carrying a variant'>Zygosity <sup className="small-text">&#9432;</sup></td>
                          <td style={{ width: "10%" }} title='Name of the Gene carrying the variation'>Associated Gene(s) <sup className="small-text">&#9432;</sup></td>
                          <td style={{ width: "10%" }} title='Whether a Variant associated with “disease” has been called out or not'>Variant Detected <sup className="small-text">&#9432;</sup></td>
                          <td style={{ width: "15%" }} title='Technical nomenclature for the “Address” on the genome where the variant is located'>Gene Variant <sup className="small-text">&#9432;</sup></td>
                        </tr>
                      </thead>

                      <tbody>
                        {
                          //TODO: remove detailed_findings after regenerating all reports in backened
                          (iemFinding?.summary_findings || iemFinding?.detailed_findings)?.map((finding) => {
                            const isVariantDetected = ReportUtils.isVariantDetected(finding?.biomarkers?.cnv_status);
                            if (isVariantDetected) {
                              return (
                                <tr>
                                  <td>
                                    <p>{finding?.subcategory[0]}</p>
                                  </td>
                                  <td>
                                    <p>{finding?.subcategory[1]}</p>
                                  </td>
                                  <td>
                                    <p>{finding?.biomarkers?.inheritance}</p>
                                  </td>
                                  <td>
                                    <p>{finding?.biomarkers?.zygosity}</p>
                                  </td>
                                  <td style={{
                                    fontStyle: 'italic',
                                    textAlign: 'start',
                                    wordBreak: 'break-word',
                                  }}>
                                    <p>{finding?.biomarkers?.gene_symbol}</p>
                                  </td>
                                  <td>
                                    <VariantStatus text={finding?.biomarkers?.relevant || finding?.biomarkers?.cnv_status} draftOrder={draftOrder} />
                                  </td>
                                  <td>
                                    {finding?.biomarkers?.variant_description?.genomic_hgvs}
                                    <p style={{ margin: 0 }}>{finding?.biomarkers?.variant_description?.cdna_hgvs}</p>
                                    <p>{finding?.biomarkers?.variant_description?.protein_hgvs}</p>
                                  </td>
                                </tr>
                              )
                            }
                          })
                        }
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </>) : ""
          }

          {
            summaryDetails.length > 0 ? (
              <>
                {/* Render your second section */}
                <Row className="mt-3">
                  <Col className="d-flex">
                    {summaryDetails.some(data => data?.RISK_IMPLICATION) && (
                      <h4 className="ff1 fs30 fw600 text-muted pt-3">Risk Implications</h4>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col style={{ padding: "0px 16px" }}>
                    <div className="section-container">
                      {/* Risk Implication Section */}
                      <div className="section">
                        {summaryDetails.map((data, index) => (
                          data?.RISK_IMPLICATION && (
                            <div key={index} className="data-item">
                              <p>{data.RISK_IMPLICATION}</p>
                            </div>
                          )
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col className="d-flex">
                    {summaryDetails.some(data => data?.RECOMMENDATION) && (
                      <h4 className="ff1 fs30 fw600 text-muted pt-3">Recommendations</h4>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col style={{ padding: "0px 16px" }}>
                    <div className="section-container">
                      {/* Recommendation Section */}
                      <div className="section">
                        {summaryDetails.map((data, index) => (
                          data?.RECOMMENDATION && (
                            <div key={index} className="data-item">
                              <p>{data.RECOMMENDATION}</p>
                            </div>
                          )
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            ) : ""
          }

          <Row className="mt-5">
            <Col>
              <div style={{ display: "flex" }}>
                <h2 className="ff1 fs30 fw700">Detailed Findings</h2>
              </div>
            </Col>
          </Row>


          <div className="table-health-1 reportTable border border-dark-200">
            <Table
              striped
              responsive="lg"
              className="table report-table mb-0 detailed-report-table"
            >
              <thead className="row-head">
                <tr className="fw600 bg-primary bg-gradient bg-opacity-50 text-white text-nowrap">
                  <td title='Individual disorders bundled together due to similar clinical features or biological pathway of origin'>
                    Broad Disorder Type <sup className="small-text">&#9432;</sup>
                  </td>
                  <td style={{ width: "auto" }} title='Specific clinical disease identified by the variant seen in the gene associated with the cause of disease'>Specific Disorder <sup className="small-text">&#9432;</sup></td>
                  <td style={{ width: "20%" }} title='Variants in gene(s) that are known to cause the specific disorder'>Associated Gene(s) <sup className="small-text">&#9432;</sup></td>
                  <td style={{ width: "15%" }} title='Genes that contain variants that are likely to be disease causing'> Gene(s) with Variants <sup className="small-text">&#9432;</sup></td>
                  <td title='Variant in a gene that can cause disease is present or absent'>Variant Detected <sup className="small-text">&#9432;</sup></td>
                </tr>
              </thead>
              <div></div>
              <tbody>
                {metaTypes.map((item) => (
                  <tr className="row-color">
                    <td>{getDisorderType(item?.diorderType)} </td>
                    <td>
                      <div className="dropdown">
                        <button className="btn btn-link text-start" onClick={item?.popupFunction}>
                          {item?.title}
                        </button>
                        <div className="dropdown-content popToolTip">
                          <p>
                            {item.icard}
                          </p>
                          <Link
                            onClick={item.popupFunction}
                            style={{
                              cursor: "pointer",
                              fontSize: "12px",
                              color: "#0000EE",
                            }}
                          >
                            Read more
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td style={{
                      fontStyle: 'italic',
                      textAlign: 'start',
                      wordBreak: 'break-word',
                    }}>
                      {item?.associatedGene.join(", ")}
                      {/* {item?.associatedGene.map((gene, idx, genes) => <div style={{ paddingRight: '4px' }}>{gene}{idx !== genes.length - 1 && ', '}</div>)} */}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {iemFinding?.detailed_findings?.map((detailed_finding) => {
                        const subcategory = detailed_finding?.subcategory;
                        const isVariantDetected = ReportUtils.isVariantDetected(detailed_finding?.biomarkers?.cnv_status);
                        if (isVariantDetected && subcategory?.includes(item?.title)) {
                          return (
                            <button
                              className="highlighted-variant"
                              onClick={() =>
                                openPopup(detailed_finding?.biomarkers)
                              }
                            >
                              {detailed_finding?.biomarkers?.gene_symbol}
                            </button>
                          )
                        }
                      })}
                    </td>
                    <td>
                      <VariantStatus text={ReportUtils.getVariantDetectedText(item, patientGender, iemFinding?.detailed_findings)} gene={item?.associatedGene[0]} draftOrder={draftOrder} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <Container fluid>
            <Row className="mt-5">
              <Col className="p-c2">
                <div className="d-flex flex-column">
                  <div className="d-md-flex flex-column report-buttons  text-center text-md-left">
                    <button
                      className="schApp text-center position-relative"
                      onClick={naviagteToContact}
                    >
                      <span className="schIcon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="60"
                          height="60"
                          fill="#000"
                          className="bi bi-telephone"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                      </span>
                      {""}Schedule appointment with Genetic Counsellor for detailed
                      explanation
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          {/* <Row>
            <Col>
              <div style={{ display: "flex" }}>
                <h2 className="ff1 fs30 fw600">Recommendations</h2>
              </div>
            </Col>
          </Row> */}

          <Row>
            <Col lg={12} md={6} xs={12}>
              <div className="mb-3">
                {/* <p className="fs18">
                  To understand the implications of the above variants, if any,
                  please schedule a report consultation with a Strand genetic
                  counselor. Subsequently, you can also download the detailed
                  report below and take it to a medical specialist.
                </p> */}
                <h4 className="fs18 fw800 fst-italic">Disclaimer</h4>
                <p className="pt-0 fs18 fst-italic">
                  {" "}
                  Note that the above information is not intended for any action
                  on your part for diagnosis, cure, mitigation, treatment or
                  prevention of disease. Any action based on this information
                  should be taken only on the recommendation of a medical
                  specialist.
                </p>
              </div>
            </Col>
          </Row>
          <div>
            {/* <p className="c2 ms-3">
            A thorough analysis of genes across 74 conditions associated
                with Adult Onset In-born Errors of Metabolism was conducted.
            </p> */}
            <div className="d-flex flex-column report-buttons align-self-center">
              <button className="btn7 mt-5" onClick={() => ReportUtils.downloadReport(orderId, ReportUtils.reportTypes.metabolic_risk)}>
                <span>{<DownloadIcon />}</span>
                Download Report (PDF)
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
