import React, { useState, useEffect } from "react";
import "./Pharmarepo.css";
import Table from "react-bootstrap/Table";
import Sidepanel from "../../components/side-panel/side-panel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useRef } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { GET_SINGLE_REPORT_URL } from "../../config";
import ReportUtils from "../../utils/ReportUtils";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import api from "../../utils/api";
import config from "../../config";

export default function PharmaRepo(props) {
  const location = useLocation();
  const [draftOrderDetail, setdraftOrderDetail] = useState(null);
  const [orderId, setOrderId] = useState("");
  const [draftOrderPdf, setdraftOrderPdf] = useState([]);

  const [draftOrder, setDraftOrder] = useState(null);
  const [queryParameters] = useSearchParams()
  useEffect(() => {
    getUrlData()
  }, [])
  const getUrlData = async () => {
    let orderId = queryParameters.get("orderId");
    setOrderId(orderId);
    const reports = await api({
      url: GET_SINGLE_REPORT_URL,
      method: "POST",
      params: { orderId },
    });
    console.log("new log carpo", reports);
    setDraftOrder(reports?.data);
    setdraftOrderDetail(reports?.data[0]);
    console.log("draftOrdersPharma", draftOrderDetail)
  }
  const topRef = useRef();
  const [isPanelOpen, setPanelOpen] = useState(false);
  props.onLoad(true);
  function openPanel(event) {
    setPanelOpen(true);
  }

  function onPanelClose() {
    setPanelOpen(false);
  }

  const navgate = useNavigate();
  const naviagteToContact = () => {
    navgate("/contact");
  };

  useEffect(() => {
    topRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  const renderPGxDevNotes = () => {
    const comments = draftOrderDetail?.Report_JSON?.report?.findings?.find(
      (finding) =>
        finding?.category === "Pharmacogenomics (PGx) Report"
    ).comments;

    if (!comments || comments.length == 0) {
      return (
        <ul style={{ marginLeft: '40px' }}>
          <li className="pt-0 fs18 fst-italic">Not Available</li>
        </ul>
      )
    }

    return (
      <ol style={{ marginLeft: '40px' }}>
        {comments?.map((comment) => <li style={{ fontWeight: 'bold' }}>{comment}</li>)
        }
      </ol>
    )
  }

  return (
    <div className="pageWrapper2">
      <div className="innerWrapper container-fluid">
        <div ref={topRef}></div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadForMobile">
            <li className="breadcrumb-item">
              <a href={"#/dashboard" + location.search}>Dashboard</a>
            </li>
            <li className="breadcrumb-item marginTop">
              <a href={"#/pharmacogenomics" + location.search}>Pharmacogenomics</a>
            </li>

            <li
              className="breadcrumb-item active bg1 marginTop"
              aria-current="page"
            >
              Results
            </li>
          </ol>
        </nav>
        {isPanelOpen && (
          <Sidepanel open={isPanelOpen} onCloseCallback={onPanelClose} />
        )}

        <div className="card mt-1 p-3 p-md-5 pt-md-2 bxShdow bdrNone cardTransparent">
          <Row>
            <Col className="d-flex mt-3">

              <h2 className="ff2 fw700 mt-3">Summary of PGx Result</h2>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="card bdrNone table-responsive">
                <Table
                  className="table table-responsive summary-report-table"
                >
                  <tbody>
                    <tr className="text-nowrap">
                      <td className="headCell1">
                        Therapeutic area
                      </td>
                      <td className="headCell2">
                        Drugs to be used with standard precaution
                      </td>
                      <td className="headCell3">
                        Drugs to be used with caution
                      </td>
                      <td className="headCell4">
                        Consider alternative drug
                      </td>
                      <td className="headCell5">
                        Currently no recommended guidelines
                      </td>
                    </tr>

                    {draftOrderDetail?.Report_JSON?.report?.findings
                      ?.find((finding) => finding?.category === "Pharmacogenomics (PGx) Report")
                      ?.summary
                      ?.sort((a, b) => (a?.therapeutic_area ?? "").localeCompare(b?.therapeutic_area ?? ""))
                      ?.map((item, index) => (
                        <tr key={index} className="text-center">
                          <td className="cellBg1">{item?.therapeutic_area}</td>
                          <td className="cellBg2">
                            {item?.standard_precaution?.join(", ")}
                          </td>
                          <td className="cellBg3">
                            {item?.use_with_caution?.join(", ")}
                          </td>
                          <td className="cellBg4">
                            {item?.consider_alternative?.join(", ")}
                          </td>
                          <td className="cellBg5">
                            {item?.no_recommended_guidelines?.join(", ")}
                          </td>
                        </tr>
                      ))
                    }

                  </tbody>
                </Table>
                {/* Create a footnote for the table */}
                <div className="footnote">
                  <div className="container">
                    <div className="row p-2">
                      <div className="col-1">
                        <div><img src="/assets/images/right.png"></img></div>
                      </div>
                      <div className="col-4">
                        <div className="text-wrap small">
                          The genotype is unlikely to affect the metabolism or cause any adverse effect. The medication can be prescribed to the patient in accordance with the standard guidelines and recommendations.
                        </div>
                      </div>
                    </div>
                    <div className="row p-2">
                      <div className="col-1">
                        <div><img src="/assets/images/wrong.png"></img></div>
                      </div>
                      <div className="col-4">
                        <div className="text-wrap small">
                          The genotype might significantly affect the metabolism of the drug or can have an adverse effect. The patient might not benefit from the medication. An alternate medication is recommended.
                        </div>
                      </div>
                    </div>
                    <div className="row p-2">
                      <div className="col-1">
                        <div><img src="/assets/images/warning.png"></img></div>
                      </div>
                      <div className="col-4">
                        <div className="text-wrap small">
                          The genotype is likely to have a some effect on the metabolism of the drug or there is evidence suggesting likely adverse effects or loss of efficacy. Appropriate dosing adjustment is required while prescribing the medication. Possibility of adverse reaction may exist for few medications. Increased vigilance is required for drugs where a possibility of adverse reaction exists.
                        </div>
                      </div>
                    </div>
                    <div className="row p-2">
                      <div className="col-1">
                        <div><img src="/assets/images/question.png"></img></div>
                      </div>
                      <div className="col-4">
                        <div className="text-wrap small">
                          Currently no recommended guidelines are available for this genotype. Please proceed with caution and consider clinical judgment, patient history, and other relevant factors when prescribing medication.
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="footnote">
                  <p>
                    <b><i>In case of conflicting recommendation, please refer to the detailed findings in the table below for evidence based decision making.</i></b> <br />
                  </p>

                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="d-flex mt-3">

              <h2 className="ff2 fw700 mt-3">Detailed Findings</h2>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="card bdrNone table-responsive ">
                {/* <h2> Detailed PGx Results</h2> */}
                <Table className="drug-table-1 table table-responsive table-bordered detailed-report-table">
                  <thead>
                    <tr className="text-nowrap">
                      <td className="headCell1" style={{ width: "auto" }}>
                        Key action
                      </td>
                      <td className="headCell1" style={{ width: "auto" }}>
                        Therapeutic area
                      </td>
                      <td className="headCell1" style={{ width: "auto" }}>
                        Drug
                      </td>
                      <td className="headCell1" style={{ width: "30%" }}>
                        Drug Class
                      </td>
                      <td className="headCell1" style={{ width: "auto" }}>
                        Gene
                      </td>
                      <td className="headCell1" style={{ width: "auto" }}>
                        Genotype
                      </td>
                      <td className="headCell1" style={{ width: "auto" }}>
                        Phenotype
                      </td>
                      <td className="headCell1" style={{ width: "auto" }}>
                        Cinical interpretation
                      </td>
                      <td className="headCell1" style={{ width: "auto" }}>
                        Recommendation
                      </td>

                      <td className="headCell1" style={{ width: "auto" }}>
                        Evidence level
                      </td>
                    </tr>
                  </thead>
                  <tbody className="tabpharma">
                    {draftOrderDetail?.Report_JSON?.report?.findings
                      ?.find(
                        (finding) =>
                          finding?.category === "Pharmacogenomics (PGx) Report"
                      )
                      ?.detailed_findings?.map((item) => {
                        let className, keyActionIcon;
                        switch (item.key_action) {
                          case 'Y':
                            className = "backrowY";
                            keyActionIcon = <DoneRoundedIcon style={{ color: "green" }} />;
                            break;
                          case 'X':
                            className = "backrowX";
                            keyActionIcon = <CloseIcon style={{ color: "red" }} />;
                            break;
                          case 'N':
                            className = "backrowN";
                            keyActionIcon = <HelpOutlineIcon style={{ color: "grey" }} />;
                            break;
                          default:
                            className = "backrowW";
                            keyActionIcon = <WarningAmberRoundedIcon style={{ color: "orange" }} />;
                            break;
                        }
                        return (
                          <tr
                            className={className}
                          >
                            <td className="text-center">
                              {keyActionIcon}
                            </td>
                            <td className>{item?.therapeutic_area}</td>
                            <td>{item.drug}</td>
                            <td style={{ width: "30%" }}>
                              <span style={{ width: "300px" }}>
                                {item.drug_information}
                              </span>
                            </td>
                            <td>{item.gene}</td>
                            <td>{item.genotype}</td>
                            <td>{item.phenotype}</td>
                            <td>{item.clinical_interpretation}</td>
                            <td>{item.recommendation}</td>

                            <td className="text-center">
                              {"*".repeat(item.evidence_level)}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {/* Create a footnote for the table */}
                <div className="footnote text-end">
                  <p>

                    <b>Evidence level</b>
                    <i>&nbsp;&nbsp; *** Evidence in FDA as Actionable PGx </i>
                    <i>&nbsp;&nbsp; ** Evidence in PharmGKB, CPIC as actionable PGx </i>
                    <i>&nbsp;&nbsp; * Evidence in medical literature </i>
                  </p>
                </div>
                <div className="footnote">
                  <p>
                    <b><i>Disclaimer</i></b>
                    <ul>
                      <li>
                        <i>The findings provide a predicted response of the patient based on the genotype information. The pharmacogenomics report does not replace the need for therapeutic monitoring. Physicians should consider a complete patient profile prior to recommending medication. The current prescriptions, symptoms, family history, and other factors need to be considered before making any clinical or therapeutic decisions.</i>
                      </li>
                      <li>
                        <i>The results provide genotype-drug interaction only for those that have a high level of evidence FDA (Testing required/Testing recommended/Actionable), PharmGKB evidence (1A, 1B, 2A, 2B) and CPIC evidence (A,B). There could be gene-drug interactions that are currently having lower evidence but are likely to obtain higher evidence in the future with more available data.</i>
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          {
            !config.isUserClient() &&
            <Row style={{ marginTop: '20px', marginLeft: '3px', marginRight: '3px', outlineStyle: 'solid' }}>
              <Row>
                <Col className="d-flex mt-3">
                  <h2 className="ff2 fw600 mt-3 text-secondary">Notes</h2>
                </Col>
              </Row>
              <Row>
                {renderPGxDevNotes()}
              </Row>
            </Row>
          }

          <Row className="mt-5">
            <Col className="p-c2">
              <div className="d-flex flex-column">
                <div className="d-md-flex flex-column report-buttons  text-center text-md-left">
                  <button
                    className="schApp text-center position-relative"
                    onClick={naviagteToContact}
                  >
                    <span className="schIcon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        fill="#000"
                        className="bi bi-telephone"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                      </svg>
                    </span>
                    {""}Schedule appointment with Genetic Counsellor for detailed
                    explanation
                  </button>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="d-flex">

              <h2 className="ff2 fw600 mt-3 text-secondary">
                {" "}

                Recommendations
              </h2>
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={6} xs={12}>
              <div>
                <p className=" fs18">
                  To understand the implications of the above variants, if any,
                  please schedule a report consultation with a Strand genetic
                  counselor. Subsequently, you can also download the detailed
                  report below and take it to a medical specialist.
                </p>

                <h4 className="fs18 fw800 fst-italic">Disclaimer</h4>
                <p className="pt-0 fs18 fst-italic">
                  {" "}
                  Note that the above information is not intended for any action
                  on your part for diagnosis, cure, mitigation, treatment or
                  prevention of disease. Any action based on this information
                  should be taken only on the recommendation of a medical
                  specialist.
                </p>
              </div>
            </Col>
          </Row>
          <button className="btn7 mt-5" onClick={() => ReportUtils.downloadReport(orderId, ReportUtils.reportTypes.pgx)}>
            <span>{<DownloadIcon />}</span>
            Download Report (PDF)
          </button>
        </div>
      </div>
    </div >
  );
}
