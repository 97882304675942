import React, { useState, useEffect } from "react";
// import "./cancer-report.css";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import PopService from "../../services/PopService";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useRef } from "react";
import ModalService from "../../services/ModalService";
import { GET_SINGLE_REPORT_URL } from "../../config";
import ReportUtils from "../../utils/ReportUtils";
import VariantStatus from "../variant-status/variant-status";
import DownloadIcon from "@mui/icons-material/Download";
import api from "../../utils/api";
import curation from "../../utils/curation";
import { ENV } from "../../config/index"
import NotesAndLimitationsModal from "../notes-and-limitations-modal/NotesAndLimitationsModal";


export default function CancerReport(props) {
  const location = useLocation();
  const isLoggedin = localStorage.getItem("isLoggedin");
  const [draftOrder, setDraftOrder] = useState(null);
  const [orderId, setOrderId] = useState("");
  const [order, setOrder] = useState([]);
  const [draftOrderPdf, setdraftOrderPdf] = useState([]);
  const [patientGender, setPatientGender] = useState(null);


  const [crrFinding, setCrrFinding] = useState(null);
  const [queryParameters] = useSearchParams()
  const [summaryDetails, setSummaryDetails] = useState([]);
  const [gene_values, setgeneValues] = useState(new Set());
  const [error, setError] = useState(null);

  useEffect(() => {
    getUrlData();
  }, [])


  const getUrlData = async () => {
    let orderId = queryParameters.get("orderId")
    setOrderId(orderId);
    const reports = await api({
      url: GET_SINGLE_REPORT_URL,
      method: "POST",
      params: { orderId },
    });

    setDraftOrder(reports?.data[0]);
    setPatientGender(reports?.data[0]?.Report_JSON?.report?.demographics?.gender);
    setCrrFinding(reports?.data[0]?.Report_JSON?.report?.
      findings?.find((finding) => finding?.category === "Hereditary Cancer Risk Report"));
  }

  const geneValues = new Set()

  useEffect(() => {
    // console.log("useEffect 2");
    if (ENV === 'development') {
      fetchData();
    }

  }, [gene_values]);

  const fetchData = async () => {
    // console.log("fetchdata");
    try {
      const promises = Array.from(gene_values).map(async (gene) => {
        // console.log(`Fetching data for gene: ${gene}`);
        const response = await api({
          url: '/summary/SummaryData',
          method: 'GET',
          params: { type: gene === 'NEGATIVE' ? 'All panels' : 'Cancer', bucket: gene },
        });

        // console.log(`Response for ${gene}:`, response.data.data);

        return response.data.data;
      });

      const data = await Promise.all(promises);

      setSummaryDetails(data);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    // console.log("useEffect 3");
    (crrFinding?.summary_findings || crrFinding?.detailed_findings)?.map((finding) => {
      const isVariantDetected = ReportUtils.isVariantDetected(finding?.biomarkers?.cnv_status);
      if (isVariantDetected) {
        geneValues.add(finding?.biomarkers?.gene_symbol);
      }
    })
    if (geneValues.size === 0) {
      geneValues.add("NEGATIVE");
    }
    else {
      geneValues.add("Any");
    }

    setgeneValues(geneValues);
  }, [crrFinding])

  // const cancerTypes = [
  //   {
  //     value: "breast",
  //     title: "Breast",
  //     associatedGene: [
  //       "ATM",
  //       "BARD1",
  //       "BRCA1",
  //       "BRCA2",
  //       "BRIP1",
  //       "CDH1",
  //       "CHEK2",
  //       "MLH1",
  //       "NBN",
  //       "NF1",
  //       "PALB2",
  //       "PTEN",
  //       "RAD51C",
  //       "RAD51D",
  //       "STK11",
  //       "TP53",
  //     ],
  //     icard:
  //       "Breast cancer is a disease in which certain cells in the breast become abnormal and multiply uncontrollably to form a tumor.",
  //     popupFunction: function openModal() {
  //       ModalService.open(
  //         "Breast Cancer",
  //         "Breast cancer is a disease in which certain cells in the breast become abnormal and multiply uncontrollably to form a tumor.In both women and men, the most common form of breast cancer begins in cells lining the milk ducts (ductal cancer). In women, cancer can also develop in the glands that produce milk (lobular cancer). In its early stages, breast cancer usually does not cause pain and may exhibit no noticeable symptoms. As the cancer progresses, signs and symptoms can include a lump or thickening in or near the breast; a change in the size or shape of the breast; nipple discharge, tenderness, or retraction (turning inward); and skin irritation, dimpling, redness, or scaliness."
  //       );
  //     },
  //   },
  //   {
  //     value: "ovaries",
  //     title: "Ovarian",
  //     gender: "Female",
  //     associatedGene: [
  //       "BRCA1",
  //       "BRCA2",
  //       "BRIP1",
  //       "DICER1",
  //       "EPCAM",
  //       "MLH1",
  //       "MSH2",
  //       "MSH6",
  //       "PALB2",
  //       "PMS2",
  //       "PPM1D",
  //       "RAD51C",
  //       "RAD51D",
  //       "STK11",
  //       "TP53",
  //     ],
  //     icard:
  //       "The ovaries are the female reproductive organs in which egg cells are produced. In ovarian cancer, certain cells in the ovary become abnormal ",
  //     popupFunction: function openModal1() {
  //       ModalService.open(
  //         "Overies",
  //         "The ovaries are the female reproductive organs in which egg cells are produced. In ovarian cancer, certain cells in the ovary become abnormal and multiply uncontrollably to form a tumor. In its early stages, ovarian cancer usually does not cause noticeable symptoms. As the cancer progresses, signs and symptoms can include pain or a feeling of heaviness in the pelvis or lower abdomen, bloating, feeling full quickly when eating, back pain, vaginal bleeding between menstrual periods or after menopause, or changes in urinary or bowel habits."
  //       );
  //     },
  //   },
  //   {
  //     value: "endometrium",
  //     title: "Endometrial/Uterine",
  //     gender: "Female",
  //     associatedGene: [
  //       "EPCAM",
  //       "MLH1",
  //       "MSH2",
  //       "MSH6",
  //       "PMS2",
  //       "PTEN",
  //       "STK11",
  //     ],
  //     icard:
  //       "Uterus is pear-shaped, hollow and located in the pelvis between the bladder and rectum. The uterus, or womb, is the place where a baby grows when a women is pregnant.",
  //     popupFunction: function openModal2() {
  //       ModalService.open(
  //         "Endometrium",
  //         "Uterus is pear-shaped, hollow and located in the pelvis between the bladder and rectum. The uterus, or womb, is the place where a baby grows when a women is pregnant. There are different types of uterine cancers. The most common type starts in the endometrium, the lining of the uterus. This type is also called endometrial cancer. The symptoms of uterine/endometrial cancer include: abnormal vaginal bleeding or discharge, trouble urinating, pelvic pain, pain during intercourse. Uterine/endometrial cancer usually happens after menopause. It is more common in women who have obesity. There is a higher risk if estrogen-only hormone replacement therapy (menopausal hormone therapy) is taken for many years."
  //       );
  //     },
  //   },
  //   {
  //     value: "prostateGland",
  //     title: "Prostate",
  //     gender: "Male",
  //     associatedGene: [
  //       "ATM",
  //       "BRCA1",
  //       "BRCA2",
  //       "BRIP1",
  //       "CHEK2",
  //       "EPCAM",
  //       "HOXB13",
  //       "MLH1",
  //       "MSH2",
  //       "MSH6",
  //       "NBN",
  //       "PALB2",
  //       "TP53",
  //     ],
  //     icard:
  //       "Prostate cancer is a common disease that affects men, usually in middle age or later. In this disorder, certain cells in the prostate become abnormal",
  //     popupFunction: function openModal4(event) {
  //       ModalService.open(
  //         "Prostate Gland",
  //         "Prostate cancer is a common disease that affects men, usually in middle age or later. In this disorder, certain cells in the prostate become abnormal, multiply without control or order, and form a tumor. The prostate is a gland that surrounds the male urethra and helps produce semen, the fluid that carries sperm. Early prostate cancer usually does not cause pain, and most affected men exhibit no noticeable symptoms. Men are often diagnosed as the result of health screenings, such as a blood test for a substance called prostate specific antigen (PSA) or a medical exam called a digital rectal exam (DRE). As the tumor grows larger, signs and symptoms can include difficulty starting or stopping the flow of urine, a feeling of not being able to empty the bladder completely, blood in the urine or semen, or pain with ejaculation."
  //       );
  //     },
  //   },
  //   {
  //     value: "stomach",
  //     title: "Stomach",
  //     associatedGene: [
  //       "APC",
  //       "BMPR1A",
  //       "CDH1",
  //       "KIT",
  //       "MLH1",
  //       "MSH2",
  //       "MSH6",
  //       "PDGFRA",
  //       "PMS2",
  //       "SDHA",
  //       "SDHB",
  //       "SDHC",
  //       "SMAD4",
  //       "STK11",
  //     ],
  //     icard:
  //       "The stomach is an organ between the esophagus and the small intestine. It mixes food with stomach acid and helps digest",
  //     popupFunction: function openModal5(event) {
  //       ModalService.open(
  //         "Stomach",
  //         "The stomach is an organ between the esophagus and the small intestine. It mixes food with stomach acid and helps digest protein. Several types of cancer can occur in the stomach. The most common type is called adenocarcinoma. It starts from one of the cell types found in the lining of the stomach. Indigestion and stomach discomfort can be symptoms of early cancer. In advanced cases, there may be blood in the stool, vomiting, unexplained weight loss, jaundice, or trouble swallowing."
  //       );
  //     },
  //   },
  //   {
  //     value: "colorectal",
  //     title: "Colorectal",
  //     associatedGene: [
  //       "APC",
  //       "BMPR1A",
  //       "CDH1",
  //       "CHEK2",
  //       "EPCAM",
  //       "MLH1",
  //       "MSH2",
  //       "MSH6",
  //       "MUTYH*",
  //       "PMS2",
  //       "PTEN",
  //       "SMAD4",
  //       "STK11",
  //       "TP53",
  //     ],
  //     icard:
  //       "Colorectal cancer occurs when tumors form in the lining of the large intestine (also calles as colon) and rectum.",
  //     popupFunction: function openModal8(event) {
  //       ModalService.open(
  //         "Colorectal",
  //         "Colorectal cancer occurs when tumors form in the lining of the large intestine (also calles as colon) and rectum. It is common in both men and women. The risk of developing colorectal cancer rises after age 50. The risk is usually high if an individual has colorectal polyps, a family history of colorectal cancer, ulcerative colitis or Crohn's disease, high dietary fat, or smoking habit. Symptoms of colorectal cancer include: diarrhea or constipation, a feeling that the bowel does not empty completely, blood (either bright red or very dark) in the stool, stools that are narrower than usual, frequent gas pains or cramps, or feeling full or bloated, weight loss with no known reason, fatigue, nausea or vomiting."
  //       );
  //     },
  //   },

  //   {
  //     value: "kidney",
  //     title: "Kidney",
  //     associatedGene: [
  //       "BAP1",
  //       "FH",
  //       "FLCN",
  //       "MET",
  //       "MSH2",
  //       "PTEN",
  //       "SDHA",
  //       "SDHB",
  //       "SDHC",
  //       "SDHD",
  //       "VHL",
  //     ],
  //     icard:
  //       "Kidneys are fist-sized organs on either side of the backbone above the waist. The tubes inside filter and clean the blood",
  //     popupFunction: function openModal6(event) {
  //       ModalService.open(
  //         "Kidney",
  //         "Kidneys are fist-sized organs on either side of the backbone above the waist. The tubes inside filter and clean the blood, taking out waste products and making urine. Kidney cancer forms in the lining of tiny tubes inside the kidneys. Renal cell carcinoma is a type of kidney cancer that starts in the lining of very small tubes (tubules) in the kidney. It is the most common type of kidney cancer in adults. It occurs most often in men 60 to 70 years old. Risk factors include smoking, having certain genetic conditions, and misusing pain medicines for a long time. There might not be any symptoms at first. They may appear as the cancer grows and include- blood in the urine, a lump in the abdomen, weight loss for no reason, pain in the side that does not go away and loss of appetite."
  //       );
  //     },
  //   },

  //   {
  //     value: "blood",
  //     title: "Blood",
  //     associatedGene: ["CEBPA", "GATA2", "RUNX1"],
  //     icard:
  //       "Blood cancers affect blood cells and bone marrow. Most blood cancers start in the bone marrow, the soft, sponge-like material in the center of the bones.",
  //     popupFunction: function openModal7(event) {
  //       ModalService.open(
  //         "Blood Cancer",
  //         "Blood cancers affect blood cells and bone marrow. Most blood cancers start in the bone marrow, the soft, sponge-like material in the center of the bones. Bone marrow makes stem cells that mature and become red blood cells, white blood cells and platelets. Blood cancers occur when abnormal blood cells start growing out of control, interrupting the function of normal blood cells, which fight off infection and produce new blood cells. The three main types of blood and bone marrow cancer are leukemia, lymphoma and myeloma. Blood cancer symptoms vary based on blood cancer type, but there some symptoms all three have in common: fatigue, persistent fever, drenching night sweats, unusual bleeding or bruising, unexpected or unexplained weight loss, frequent infections, swollen lymph nodes or an enlarged liver or spleen, bone pain."
  //       );
  //     },
  //   },

  //   {
  //     value: "nervousSystem",
  //     title: "Nervous system",
  //     associatedGene: [
  //       "ALK",
  //       "APC",
  //       "CDKN2A",
  //       "MEN1",
  //       "MLH1",
  //       "MSH2",
  //       "MSH6",
  //       "NF2",
  //       "PHOX2B",
  //       "PMS2",
  //       "PRKAR1A",
  //       "PTEN",
  //       "SUFU",
  //       "TP53",
  //       "VHL",
  //     ],
  //     icard:
  //       "Brain tumor is a growth of abnormal cells in the tissues of the brain. Brain tumors can be benign, with no cancer cells, or malignant, with cancer cells that grow quickly",
  //     popupFunction: function openModal9(event) {
  //       ModalService.open(
  //         "Nervous System",
  //         "Brain tumor is a growth of abnormal cells in the tissues of the brain. Brain tumors can be benign, with no cancer cells, or malignant, with cancer cells that grow quickly. Some of the most common symptoms of brain cancer are: headaches, often in the morning, nausea and vomiting, changes in the ability to talk, hear, or see, problems with balance or walking, problems with thinking or memory, feeling weak or sleepy, changes in mood or behavior, seizures."
  //       );
  //     },
  //   },

  //   {
  //     value: "skin",
  //     title: "Skin",
  //     associatedGene: [
  //       "BAP1",
  //       "BRCA2",
  //       "CDK4",
  //       "CDKN2A",
  //       "ERCC2",
  //       "ERCC3",
  //       "ERCC4",
  //       "ERCC5",
  //       "PTEN",
  //       "XPA",
  //       "XPC",
  //     ],
  //     icard:
  //       "Skin cancer is the abnormal growth of skin cells, most often develops on skin exposed to the sun",
  //     popupFunction: function openModal10(event) {
  //       ModalService.open(
  //         "Skin",
  //         "Skin cancer is the abnormal growth of skin cells, most often develops on skin exposed to the sun. The two most common types are basal cell cancer and squamous cell cancer. They usually form on the head, face, neck, hands, and arms. Another type of skin cancer, melanoma, is more dangerous but less common. Skin cancer is more common in people who spend a lot of time in the sun, have light-colored skin, hair and eyes, have a family member with skin cancer and are over age 50. The clinician should be consulted in case of any suspicious skin markings and any changes in the way skin looks."
  //       );
  //     },
  //   },

  //   {
  //     value: "pancreatic",
  //     title: "Pancreatic",
  //     associatedGene: [
  //       "APC",
  //       "ATM",
  //       "BRCA1",
  //       "BRCA2",
  //       "CDKN2A",
  //       "EPCAM",
  //       "MEN1",
  //       "MLH1",
  //       "MSH2",
  //       "MSH6",
  //       "PALB2",
  //       "SMAD4",
  //       "STK11",
  //       "TP53",
  //       "VHL",
  //     ],
  //     icard:
  //       "The pancreas is a gland behind the stomach and in front of the spine. It produces the juices that help break down food and the hormones that help control blood sugar",
  //     popupFunction: function openModal6(event) {
  //       ModalService.open(
  //         "pancreatic",
  //         "The pancreas is a gland behind the stomach and in front of the spine. It produces the juices that help break down food and the hormones that help control blood sugar levels. Pancreatic cancer usually begins in the cells that produce the juices. Pancreatic cancer is hard to catch early. It doesn't cause symptoms right away. Symptoms are often vague or not easily observed. They include yellowing of the skin and eyes, pain in the abdomen and back, weight loss and fatigue. Also, because the pancreas is hidden behind other organs, health care providers cannot see or feel the tumors during routine exams. Doctors use a physical exam, blood tests, imaging tests, and a biopsy to diagnose it."
  //       );
  //     },
  //   },
  // ];

  const cancerTypes = curation.getCurationData('Hereditary Cancer Risk Report')
  let genderMap = new Map();
  genderMap.set("Male", []);
  genderMap.set("Female", []);
  cancerTypes.map((cancer) => {
    let gender = cancer['gender'];
    if (gender === "Male") {
      genderMap.get("Male").push(cancer['value']);
    }
    else if (gender === "Female") {
      genderMap.get("Female").push(cancer['value']);
    }
  })

  props.onLoad(true);
  function openPopup(data) {
    PopService.open("Cancer Report", {
      data,
    });
  }

  // PopUp end

  const navigate = useNavigate();
  const naviagteToContact = () => {
    navigate("/contact");
  };
  const topRef = useRef();

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    topRef.current.scrollIntoView({
      behavior: "smooth",
    });
  }, []);
  let finalOrganTissue = [];

  const handleLinkClick = () => {
    window.open('https://www.nccn.org/home/about', '_blank', 'noopener');
  };

  return (
    <div className="pageWrapper2">
      {/* <ScrollToTop/> */}
      <div className="innerWrapper d-flex flex-column mb-3 container-fluid">
        <div ref={topRef}></div>

        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadForMobile">
            <li className="breadcrumb-item">
              <a href={"#/dashboard" + location.search}>Dashboard</a>
            </li>
            <li className="breadcrumb-item marginTop">
              <a href={"#/dashboard" + location.search}>Adult Onset</a>
            </li>
            <li className="breadcrumb-item marginTop">
              <a href={"#/cancer" + location.search}>Cancer</a>
            </li>
            <li
              className="breadcrumb-item active bg1 marginTop"
              aria-current="page"
            >
              Results
            </li>
          </ol>
        </nav>
        <div className="card mt-1 p-3 p-md-5 pt-md-2 bxShdow bdrNone cardTransparent">
          <Row>
            <Col>
              <h1 className="ff1 fs30 fw600 text-muted pt-3">
                Hereditary Cancer Risk Results
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="fs20 pb-4">
                {
                  crrFinding?.num_observations_of_significance > 0 ? (
                    <>
                      Your results have
                      <span
                        className={

                          crrFinding?.num_observations_of_significance !== 0
                            ? "variantHighlightRed"
                            : "variantHighlightGreen"
                        }
                      >
                        {
                          crrFinding?.num_observations_of_significance}
                      </span>
                      observation/s of significance
                    </>
                  ) :
                    <> No observation/s of significance were detected.</>
                }
                {
                  <NotesAndLimitationsModal curationData={cancerTypes} draftOrder={draftOrder} />
                }
              </h4>
            </Col>
          </Row>


          {
            crrFinding?.num_observations_of_significance > 0 ? (<>
              <Row className="mt-5">
                <Col className="d-flex">
                  <h2 className="ff1 fs20 fw700">
                    Summary of Observations of Significance
                  </h2>
                </Col>
              </Row>

              <Row>
                <Col style={{ padding: "0px 16px" }}>
                  <div className="table-responsive border border-info">
                    <Table className="table table-striped table-responsive report-table mb-0 summary-report-table">
                      <thead className="bg-info bg-gradient">
                        <tr className="fw600 bg-info bg-gradient bg-opacity-50 text-nowarp">
                          <td title='The site of origin of the cancer'>Organ/Tissue <sup className="small-text">&#9432;</sup></td>
                          <td title='Type of chromosome on which a gene is located (autosomal or X linked) and whether the variant is likely to manifest as disease (dominant or recessive). Eg; An “Autosomal Dominant” or “AD” allocation refers to the gene being autosomal in origin and the nature of the variant being “dominant” or “likely disease causing”'>Inheritance <sup className="small-text">&#9432;</sup></td>
                          <td title='Number of copies of a gene variant. Heterozygous refers to a single copy of the gene variant and Homozygous refers to both copies of a gene carrying a variant'>Zygosity <sup className="small-text">&#9432;</sup></td>
                          <td title='Name of the Gene carrying the variation'>Associated Gene(s) <sup className="small-text">&#9432;</sup></td>
                          <td title='Whether a Variant associated with “disease” has been called out or not'>Variant Detected <sup className="small-text">&#9432;</sup></td>
                          <td title='Technical nomenclature for the “Address” on the genome where the variant is located'>Gene Variant <sup className="small-text">&#9432;</sup></td>
                        </tr>
                      </thead>

                      <tbody>
                        {
                          //TODO: remove detailed_findings after regenerating all reports in backened
                          (crrFinding?.summary_findings || crrFinding?.detailed_findings)?.map((finding) => {
                            const isVariantDetected = ReportUtils.isVariantDetected(finding?.biomarkers?.cnv_status);
                            if (isVariantDetected) {
                              let organTissue = finding?.subcategory[0].split(', ');

                              if (patientGender == "Male") {
                                finalOrganTissue = organTissue.filter((item) => !genderMap.get("Female").includes(item));
                              }
                              else if (patientGender == "Female") {
                                finalOrganTissue = organTissue.filter((item) => !genderMap.get("Male").includes(item));
                              }
                              return (
                                <tr>
                                  <td>
                                    <p>{finalOrganTissue.join(',')}</p>
                                  </td>
                                  <td>
                                    <p>{finding?.biomarkers?.inheritance}</p>
                                  </td>
                                  <td>
                                    <p>{finding?.biomarkers?.zygosity}</p>
                                  </td>
                                  <td style={{
                                    fontStyle: 'italic',
                                    textAlign: 'start',
                                  }}>
                                    <p>{finding?.biomarkers?.gene_symbol}</p>
                                  </td>
                                  <td>
                                    <VariantStatus text={finding?.biomarkers?.relevant || finding?.biomarkers?.cnv_status} draftOrder={draftOrder} />
                                  </td>
                                  <td>
                                    {finding?.biomarkers?.variant_description?.genomic_hgvs}
                                    <p style={{ margin: 0 }}>{finding?.biomarkers?.variant_description?.cdna_hgvs}</p>
                                    <p>{finding?.biomarkers?.variant_description?.protein_hgvs}</p>
                                  </td>
                                </tr>
                              )
                            }
                          })
                        }
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </>) : ""
          }

          {
            summaryDetails.length > 0 ? (
              <>
                {/* Checking if NCCN Guidelines section should be rendered */}
                {summaryDetails.some(data =>
                  Object.keys(data?.attributes || {}).some(
                    organ => finalOrganTissue.includes(organ) && data.attributes[organ] !== ""
                  )
                ) && (
                  <>
                    <Row className="mt-4">
                      <h4 className="ff1 fs30 fw600 text-muted pt-3">
                        NCCN Guidelines&nbsp; 
                        <div className="dropdown">
                          <sup style={{ cursor: 'pointer', fontSize: '10px' }}>&#9432;</sup>
                          <div className="dropdown-content popToolTip">
                          <p style={{ fontSize: '16px' }}>The National Comprehensive Cancer Network <sup>&reg;</sup>&nbsp;(NCCN)</p>
                            <Link
                              onClick={handleLinkClick}
                              style={{
                                cursor: "pointer",
                                fontSize: "12px",
                                color: "#0000EE",
                              }}
                            >
                              NCCN Guidelines
                            </Link>
                          </div>
                        </div>
                      </h4>
                    </Row>
                    {/* Render first section */}
                    <Row className="mt-5">
                      {summaryDetails.map((data) => (
                        <Row key={data.id} className="mb-4">
                          {data?.attributes &&
                            Object.entries(data.attributes)
                              .filter(([organ, value]) => finalOrganTissue.includes(organ) && value !== "")
                              .map(([organ, value]) => (
                                <Col key={organ} className="mb-3">
                                  <div className="card mx-3" style={{ width: '100%', boxShadow: '0 8px 12px rgba(0, 0, 0, 0.2)' }}>
                                    <div className="card-body">
                                      <h5 className="card-title" style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>{organ}</h5>
                                      <p className="card-text" dangerouslySetInnerHTML={{ __html: value }} />
                                    </div>
                                  </div>
                                </Col>
                              ))}
                        </Row>
                      ))}
                    </Row>
                  </>

                )}

                {/* Render your second section */}
                <Row className="mt-5">
                  <Col className="d-flex">
                    {summaryDetails.some(data => data?.RISK_IMPLICATION) && (
                      <h4 className="ff1 fs30 fw600 text-muted pt-3">Risk Implications</h4>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col style={{ padding: "0px 16px" }}>
                    <div className="section-container">
                      {/* Risk Implication Section */}
                      <div className="section">
                        {summaryDetails.map((data, index) => (
                          data?.RISK_IMPLICATION && (
                            <div key={index} className="data-item">
                              <p>{data.RISK_IMPLICATION}</p>
                            </div>
                          )
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col className="d-flex">
                    {summaryDetails.some(data => data?.RECOMMENDATION) && (
                      <h4 className="ff1 fs30 fw600 text-muted pt-3">Recommendations</h4>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col style={{ padding: "0px 16px" }}>
                    <div className="section-container">
                      {/* Recommendation Section */}
                      <div className="section">
                        {summaryDetails.map((data, index) => (
                          data?.RECOMMENDATION && (
                            <div key={index} className="data-item">
                              <p>{data.RECOMMENDATION}</p>
                            </div>
                          )
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            ) : ""
          }

          <Row className="mt-5">
            <Col>
              <div style={{ display: "flex" }}>
                <h2 className="ff1 fs30 fw700">Detailed Findings</h2>
              </div>
            </Col>
          </Row>

          <div className="table-health-1 reportTable border border-dark-200">
            <Table
              striped
              responsive="lg"
              className="table report-table mb-0 detailed-report-table"
            >
              <thead className="row-head">
                <tr className="fw600 bg-primary bg-gradient bg-opacity-50 text-white text-nowrap">
                  <td title='Every cancer has a tissue of origin and this column highlights the various tissue types where variants of specific genes can result in the development of cancer'>Organ/Tissue <sup className="small-text">&#9432;</sup></td>

                  <td title='Variants in gene(s) that are known to cause cancer in the organ or tissue type that is highlighted'> Associated Gene(s) <sup className="small-text">&#9432;</sup></td>
                  <td title='This specifically calls out Genes that contain variants that are likely to be disease causing'> Gene(s) with Variants <sup className="small-text">&#9432;</sup></td>
                  <td title='Whether a variant in a gene that can cause cancer in the associated tissue is present or absent'>Reportable Mutation Detected <sup className="small-text">&#9432;</sup></td>
                </tr>
              </thead>
              <tbody>
                {cancerTypes.map((item) => (
                  <tr className="row-color">
                    <td className="firstRowRight">
                      <div className="dropdown">
                        <button className="btn btn-link text-start" onClick={item?.popupFunction}>
                          {item?.title}
                        </button>
                        <div className="dropdown-content popToolTip">
                          <p>
                            {item?.icard}
                          </p>
                          <Link
                            onClick={item?.popupFunction}
                            style={{
                              cursor: "pointer",
                              fontSize: "12px",
                              color: "#0000EE",
                            }}
                          >
                            Read more
                          </Link>
                        </div>
                      </div>{" "}
                    </td>
                    <td style={{
                      fontStyle: 'italic',
                      textAlign: 'start',
                      wordBreak: 'break-word',
                    }}>
                      {item?.associatedGene.join(", ")}
                      {/* {item?.associatedGene.map((gene, idx, genes) => <div style={{ paddingRight: '4px' }}>{gene}{idx !== genes.length - 1 && ', '}</div>)} */}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {crrFinding?.detailed_findings?.map((detailed_finding) => {
                        const subcategory = detailed_finding?.subcategory;
                        const isVariantDetected = ReportUtils.isVariantDetected(detailed_finding?.biomarkers?.cnv_status);
                        if (isVariantDetected && subcategory?.includes(item?.title)) {
                          return (
                            <button
                              className="highlighted-variant"
                              onClick={() =>
                                openPopup(detailed_finding?.biomarkers)
                              }
                            >
                              {detailed_finding?.biomarkers?.gene_symbol}
                            </button>
                          )
                        }
                      })}
                    </td>
                    <td>
                      <VariantStatus text={ReportUtils.getVariantDetectedText(item, patientGender, crrFinding?.detailed_findings)} gene={item?.associatedGene[0]} draftOrder={draftOrder} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <Container fluid>
            <Row className="mt-5">
              <Col className="p-c2">
                <div className="d-flex flex-column">
                  <div className="d-md-flex flex-column report-buttons  text-center text-md-left">
                    <button
                      className="schApp text-center position-relative"
                      onClick={naviagteToContact}
                    >
                      <span className="schIcon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="60"
                          height="60"
                          fill="#000"
                          className="bi bi-telephone"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                      </span>
                      {""}Schedule appointment with Genetic Counsellor for detailed
                      explanation
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          {/* <Row>
            <Col>
              <div style={{ display: "flex" }}>
                <h2 className="ff1 fs30 fw600">Recommendations</h2>
              </div>
            </Col>
          </Row> */}

          <Row>
            <Col lg={12} md={6} xs={12}>
              <div className="mb-3">
                {/* <p className="fs18">
                  To understand the implications of the above variants, if any,
                  please schedule a report consultation with a Strand genetic
                  counselor. Subsequently, you can also download the detailed
                  report below and take it to a medical specialist.                  </p> */}
                <h4 className="fs18 fw800 fst-italic">Disclaimer</h4>
                <p className="pt-0 fs18 fst-italic">
                  {" "}
                  Note that the above information is not intended for any action
                  on your part for diagnosis, cure, mitigation, treatment or
                  prevention of disease. Any action based on this information
                  should be taken only on the recommendation of a medical
                  specialist.
                </p>
              </div>
            </Col>
          </Row>

          <button className="btn7 mt-5" onClick={() => ReportUtils.downloadReport(orderId, ReportUtils.reportTypes.cancer_risk)}>
            <span>{<DownloadIcon />}</span>
            Download Report (PDF)
          </button>
        </div>
      </div>
    </div>
  );
}
